import axios from "../utils/axios";

export function listarUsuarios() {
    return axios.get(`/api/usuarios`);
}

export function obterUsuario(id) {
    return axios.get(`/api/usuarios/${id}`);
}

export function inserirUsuario(data) {
    return axios.post(`/api/usuarios`, data)
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            throw error;
        });
}

export function atualizarUsuario(id, data) {
    return axios.put(`/api/usuarios/${id}`, data);
}

export function deletarUsuario(id) {
    return axios.delete(`/api/usuarios/${id}`);
}

export function confirmacaoEmailUsuario(email, token) {
    return axios.get(`/api/usuarios/confirmacao-email?email=${email}&token=${token}`)
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            throw error;
        });
}

export function reenviarEmailConfirmacaolUsuario(email) {
    return axios.post(`/api/usuarios/reenvio-email-confirmacao?email=${email}`, null)
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            throw error;
        });
}

export function recuperacaoSenhaUsuario(data) {
    return axios.post(`/api/usuarios/recuperacao-senhas?email=${data.email}`, null)
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            throw error;
        });
}

export function alteracaoSenhaUsuario(data, email, token) {
    return axios.post(`/api/usuarios/alteracao-senhas?senha=${data.senha}&email=${email}&token=${token}`);
}

export function detalheUsuario() {
    return axios.get(`/api/usuarios/detalhes`);
}