import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import AgricultureOutlinedIcon from '@mui/icons-material/AgricultureOutlined';
import PieChartOutlineOutlinedIcon from '@mui/icons-material/PieChartOutlineOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import TableViewIcon from '@mui/icons-material/TableView';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import SpeedIcon from '@mui/icons-material/Speed';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import BlockIcon from '@mui/icons-material/Block';
import RestoreIcon from '@mui/icons-material/Restore';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import NextPlanIcon from '@mui/icons-material/NextPlan';

const pagesSection = [
  {
    href: "/",
    icon: SpeedIcon,
    title: "Dashboard",
    key: 'dashboard',
  },
];

const adminSection = [
  {
    href: "/usuarios",
    icon: GroupOutlinedIcon,
    title: "Users",
    key: 'usuarios',
  },
  {
    href: "/produtores",
    icon: AgricultureOutlinedIcon,
    title: "Cheese Makers",
    key: 'produtores',
  },
  // {
  //   href: "/curadores",
  //   icon: HowToRegIcon,
  //   title: "Curators",
  //   key: 'curadores',
  // },
  {
    href: "/jurados",
    icon: VerifiedOutlinedIcon,
    title: "Cheese Judges",
    key: 'jurados',
  },
  {
    href: "/queijos",
    icon: PieChartOutlineOutlinedIcon,
    title: "Cheese Subscriptions",
    key: 'queijos',
  },
];

const eventSection = [
  {
    href: "/parametros-concurso",
    icon: TuneOutlinedIcon,
    title: "Contest Parameters",
    key: 'parametros-concurso',
  },
  {
    href: "/mesas",
    icon: TableViewIcon,
    title: "Contest Tables",
    key: 'mesas',
  },
  {
    href: "/tempo-real",
    icon: RestoreIcon,
    title: "Event in Real Time",
    key: 'tempo-real',
  },
  {
    href: "/classificacao-geral",
    icon: EmojiEventsIcon,
    title: "Overall Awards Results",
    key: 'classificacao-geral',
  },
  {
    href: "/classificacao-categoria",
    icon: MilitaryTechIcon,
    title: "Awards Results by Category",
    key: 'classificacao-categoria',
  },
];

const relatoriosSection = [
  {
    href: "/inscricoes-produtor",
    icon: AgricultureOutlinedIcon,
    title: "Subscriptions by Makers",
    key: 'inscricoes-produtor',
  },
  {
    href: "/produtores-sem-inscricoes",
    icon: BlockIcon,
    title: "Makers With No Subscriptions",
    key: 'produtores-sem-inscricoes',
  },
  {
    href: "/classificados-por-fase",
    icon: NextPlanIcon,
    title: "Cheeses Qualified by Phase",
    key: 'classificados-por-fase',
  },
  {
    href: "/faturamento",
    icon: PaidOutlinedIcon,
    title: "Invoices",
    key: 'pedidos',
  },
];

const juradoSection = [
  {
    href: "/avaliacoes",
    icon: RateReviewOutlinedIcon,
    title: "Reviews",
    key: 'avaliacoes',
  },
];

let navItems = [
  {
    // title: "Pages",
    pages: pagesSection,
  },
  {
    title: "Administrative",
    pages: adminSection,
  },
  {
    title: "Event",
    pages: eventSection,
  },
  // {
  //   title: "Cheese Maker",
  //   pages: produtorSection,
  // },
  {
    title: "Reports",
    pages: relatoriosSection,
  },
  // {
  //   title: "Cheese Judge",
  //   pages: juradoSection,
  // },
];

export default navItems;