import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import {
  Alert as MuiAlert,
  Checkbox,
  FormControlLabel,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormLabel,
  TextField as MuiTextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { spacing } from "@mui/system";
// import { login } from "../../api/auth";
import useAuth from "../../hooks/useAuth";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import SenhaInput from "../forms/Senha/SenhaInput";
import { reenviarEmailConfirmacaolUsuario } from "../../api/usuarios";

const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);

function SignIn() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { signIn } = useAuth();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const [sucesso, setSucesso] = useState(false);

  const openAlert = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setError('');
    setOpen(false);
  };

  const reenviarEmailConfirmacao = async (email) => {
    try {
      await reenviarEmailConfirmacaolUsuario(email);

      setSucesso(true);
      openAlert();
    }
    catch (error) {
      setError(error.notificacoes[0].message);
      openAlert();
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          email: "",
          senha: "",
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("Digite um email válido")
            .max(255)
            .required("Email é obrigatorio"),
          senha: Yup.string().max(255).required("Senha é obrigatória"),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            setLoading(true);
            // await login(values.email, values.senha);
            await signIn(values.email, values.senha);
            navigate("/");
            setLoading(false);
          } catch (error) {
            const message = error.mensagem || "User not found.";
            const statusCode = error.statusCode;
            setStatus({ success: false });
            setErrors({ submit: message, code: statusCode });
            setSubmitting(false);
            setLoading(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              className='alert-dialog'
            >
              {(open && sucesso) &&
                <div>
                  <DialogContent
                    sx={{
                      textAlign: 'center',
                      margin: 10
                    }}
                  >
                    <CheckCircleOutlineIcon
                      color='success'
                      sx={{
                        fontSize: 100,
                        marginBottom: 2
                      }}
                    />
                    <DialogContentText id="alert-dialog-description" sx={{ fontSize: 20, color: '#000' }}>
                      <span sx={{ fontSize: 32 }}>
                        {t("A confirmation e-mail was sent to the registered e-mail address.")} <br />
                      </span>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose}>
                      {t("Done")}
                    </Button>
                  </DialogActions>
                </div>
              }

              {(open && error) &&
                <div>
                  <DialogContent
                    sx={{
                      textAlign: 'center',
                      margin: 10
                    }}
                  >
                    <CancelOutlinedIcon
                      color='error'
                      sx={{
                        fontSize: 100,
                        marginBottom: 2
                      }}
                    />
                    <DialogContentText id="alert-dialog-description" sx={{ fontSize: 20, color: '#000' }}>
                      {t("Occured an Error")}<br />
                      {t(error)}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose}>
                      {t("Close")}
                    </Button>
                  </DialogActions>
                </div>
              }
            </Dialog>

            <form noValidate onSubmit={handleSubmit}>
              {errors.submit && (
                <Alert mt={2} mb={3} severity="warning">
                  {t(errors.submit)}
                  {errors.submit === 'Email não confirmado!' &&
                    <Button
                      component={Link}
                      color="primary"
                      sx={{ textDecoration: 'underline', color: '#000', padding: 0, marginBottom: 1, marginLeft: 2 }}
                      onClick={() => reenviarEmailConfirmacao(values.email)}
                    >
                      {t("Resend account validation e-mail")}
                    </Button>
                  }
                </Alert>
              )}
              <TextField
                type="email"
                name="email"
                label="Email"
                value={values.email}
                error={Boolean(touched.email && errors.email)}
                fullWidth
                helperText={touched.email && errors.email}
                onBlur={handleBlur}
                onChange={handleChange}
                my={2}
              />
              <SenhaInput
                id="senha"
                name="senha"
                label={t("Password")}
                value={values.senha}
                error={Boolean(touched.senha && errors.senha)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label={t("Remember me")}
              />
              <LoadingButton
                loading={loading}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={isSubmitting}
              >
                {t("Log In")}
              </LoadingButton>
            </form>
          </>
        )}
      </Formik>

      <Button
        component={Link}
        to="/esqueci-minha-senha"
        fullWidth
        color="primary"
      >
        {t("Forgot your password?")}
      </Button>
      <Button
        component={Link}
        to="/escolha-tipo-cadastro"
        fullWidth
        color="primary"
      >
        {t("Sign up")}
      </Button>
    </>
  );
}

export default SignIn;