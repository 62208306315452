export const tipoDocBrasilProdutor = [
    'CPF',
    'CNPJ'
];

export const tipoDocBrasilJurado = [
    'CPF',
    'RG'
];

export const tipoDocInternacionalProdutor = [
    'Passaporte',
    'ID da Propriedade'
];

export const tipoDocInternacionalJurado = [
    'Passaporte',
];