import React from "react";
import styled from "@emotion/styled";
import jwtDecode from "jwt-decode";

import { Badge, Grid, Avatar, Typography } from "@mui/material";

import useAuth from "../../hooks/useAuth";

const Footer = styled.div`
  background-color: ${(props) =>
    props.theme.sidebar.footer.background} !important;
  padding: ${(props) => props.theme.spacing(2.75)}
    ${(props) => props.theme.spacing(4)};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const FooterText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
`;

const FooterSubText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
  font-size: 0.7rem;
  display: block;
  padding: 1px;
`;

const FooterBadge = styled(Badge)`
  margin-right: ${(props) => props.theme.spacing(1)};
  span {
    background-color: ${(props) =>
    props.theme.sidebar.footer.online.background};
    border: 1.5px solid ${(props) => props.theme.palette.common.white};
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
`;

const SidebarFooter = ({ ...rest }) => {
  // const { user } = useAuth();

  let token = "";

  if (window.localStorage.getItem("accessToken")) {
    const accessToken = window.localStorage.getItem("accessToken");
    token = jwtDecode(accessToken);
  }

  let user = { name: token.nome, email: token.email };

  var stringToColour = function (str) {
    if (str) {
      var hash = 0;
      for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      var colour = "#";
      for (var i = 0; i < 3; i++) {
        var value = (hash >> (i * 8)) & 0xff;
        colour += ("00" + value.toString(16)).substr(-2);
      }
      return colour;
    }
  };

  return (
    <Footer {...rest}>
      <Grid container spacing={2}>
        <Grid item>
          <Avatar style={{ backgroundColor: stringToColour(user.name) }}>
            {user.name && user.name.charAt(0)}
          </Avatar>
        </Grid>
        <Grid item>
          <FooterText variant="body2">{user.name}</FooterText>
          <FooterSubText variant="caption">{user.email}</FooterSubText>
        </Grid>
      </Grid>
    </Footer>
  );
};

export default SidebarFooter;
