import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { Link, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
    Box,
    Button,
    Typography,
    Grid,
    CircularProgress
} from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { confirmacaoEmailUsuario } from "../../api/usuarios";
import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(6)};
  text-align: center;
  background: transparent;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

export default function ConfirmacaoEmail() {
    const { t } = useTranslation();
    const location = useLocation();
    const params = new URLSearchParams(location.search);

    const [isloading, setLoading] = useState(false);
    const [sucesso, setSucesso] = useState(false);
    const [msg, setMsg] = useState('')

    useEffect(() => {
        validaEmail(params);
    }, []);

    const validaEmail = async (params) => {
        setLoading(true);

        const email = params.get("email");
        const token = params.get("token");

        const response = await confirmacaoEmailUsuario(email, token);

        if (response.sucesso) {
            setMsg(response.mensagem);
            setSucesso(true);
            setLoading(false);
        }
        else {
            setMsg(response.notificacoes[0].message);
            setSucesso(false);
            setLoading(false);
        }
    }

    return (
        <Wrapper>
            <Helmet title="Cadastro ativado com sucesso!" />
            <>
                {isloading ? (
                    <Box display="flex" justifyContent="center" my={6}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <Grid item xs={12} sx={{ textAlign: 'center' }}>
                        {sucesso ?
                            <CheckCircleOutlineIcon
                                color='success'
                                sx={{
                                    fontSize: 80,
                                    marginBottom: 5
                                }}
                            />
                            :
                            <CancelOutlinedIcon
                                color='error'
                                sx={{
                                    fontSize: 100,
                                    marginBottom: 2
                                }}
                            />
                        }

                        <Typography variant="h4" gutterBottom>
                            {t(msg)}
                        </Typography>
                        <Grid item xs={12} sx={{ display: 'grid', marginTop: 5 }}>
                            {sucesso &&
                                <Button
                                    component={Link}
                                    to="/login"
                                    color="primary"
                                >
                                    Voltar para o Login
                                </Button>
                            }
                        </Grid>
                        {/* <Button onClick={() => navigate("/login")}>
                    Voltar para o Login
                </Button> */}
                    </Grid>
                )}
            </>
        </Wrapper>
    );
}