import React, { useState, useEffect } from 'react';
import { Formik } from "formik";
import {
    Box,
    Grid,
    Button,
    TextField,
    FormHelperText,
    MenuItem,
    CircularProgress,
    FormControlLabel,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
} from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import SenhaInput from "../../forms/Senha/SenhaInput";
import InputMask from "react-input-mask";
import * as Yup from "yup";
import YupPassword from 'yup-password';
import { useNavigate } from "react-router-dom";
import { obterPaises } from "../../../api/countriesnow";
import { inserirProdutor } from "../../../api/produtores";
import { inserirUsuario } from "../../../api/usuarios";
import { useTranslation } from "react-i18next";
import { tipoDocBrasilProdutor, tipoDocInternacionalProdutor } from '../../../api/tipoDocumentos';

YupPassword(Yup);

const formValues = {
    nomeRepresentante: '',
    nomeFazenda: '',
    cep: '',
    cidade: '',
    estado: '',
    pais: '',
    tipoDocumento: '',
    numeroDocumento: '',
    celular: '',
    telefone: '',
    email: '',
    site: '',
    termoAceito: false,
    senha: '',
    // idioma: 'pt',
    // ativo: false,
};

const validationSchema = Yup.object().shape({
    nomeRepresentante: Yup.string().required("Campo obrigatório"),
    nomeFazenda: Yup.string().required("Campo obrigatório"),
    cidade: Yup.string().required("Campo obrigatório"),
    estado: Yup.string().required("Campo obrigatório"),
    pais: Yup.string(),
    cep: Yup.string().length(9, "O CEP deve ter 8 dígitos"),
    tipoDocumento: Yup.string(),
    numeroDocumento: Yup.string(),
    celular: Yup.string().required("Campo obrigatório"),
    telefone: Yup.string().nullable(),
    email: Yup.string().email("E-mail inválido").max(255).required("Campo obrigatório"),
    site: Yup.string().nullable(),
    termoAceito: Yup.bool().required("Campo obrigatório"),
    senha: Yup.string()
        .required("Campo obrigatório")
        .min(10, 'Senha curta demais - deve conter ao menos 10 caracteres.')
        .minLowercase(1, 'A senha deve conter ao menos uma letra em caixa baixa.')
        .minUppercase(1, 'A senha deve conter ao menos uma letra em caixa alta.')
        .minNumbers(1, 'A senha deve conter ao menos um número.'),
    // idioma: Yup.string(),
});

function FormularioProdutor() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [error, setError] = useState("");
    const [open, setOpen] = useState(false);
    const [initialValues, setInitialValues] = useState(formValues);
    const [sucesso, setSucesso] = useState(false);
    const [checked, setChecked] = useState(false);
    const [paises, setPaises] = useState([]);
    const [paisValue, setPaisValue] = useState('');
    const [tipoDocumentoValue, setTipoDocumentoValue] = useState('');

    const handleSubmit = async (
        values,
        { resetForm, setErrors, setStatus, setSubmitting }
    ) => {
        try {
            var nome = values.nomeRepresentante.split(' ').slice(0, 1).join(' ');
            var sobrenome = values.nomeRepresentante.split(' ').slice(1).join(' ');

            let usuario = {
                "nome": nome,
                "sobrenome": sobrenome,
                "email": values.email,
                "senha": values.senha,
                "senhaAntiga": '',
                "funcao": "Produtor",
                "idioma": "pt",
            }

            const responseUsuario = await inserirUsuario(usuario);
            const { id } = responseUsuario.data;

            values.usuarioId = id;
            values.pais = paisValue;
            values.tipoDocumento = tipoDocumentoValue;
            values.nomeDocumento = tipoDocumentoValue;
            values.termoAceito = checked;

            await inserirProdutor(values);

            setSucesso(true);
            resetForm();
            setStatus({ sent: true });
            setSubmitting(false);
            openAlert();
        } catch (error) {
            setStatus({ sent: false });
            setError(error.notificacoes[0].message);
            setSubmitting(false);
            openAlert();
        }
    };

    //set paises
    useEffect(() => {
        const initialize = async () => {
            let paisesData = await obterPaises('pt');
            setPaises(paisesData);
        };
        initialize();
    }, []);

    const handleClose = () => {
        setError("");
        setOpen(false);
    };

    const openAlert = () => {
        setOpen(true);
    };

    return (
        <Formik
            setOpen={setOpen}
            open={open}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                status,
            }) => (
                <React.Fragment>
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        className='alert-dialog'
                    >
                        {(open && sucesso) && (
                            <div>
                                <DialogContent
                                    sx={{
                                        textAlign: 'center',
                                        margin: 10
                                    }}
                                >
                                    <CheckCircleOutlineIcon
                                        color='success'
                                        sx={{
                                            fontSize: 100,
                                            marginBottom: 2
                                        }}
                                    />
                                    <DialogContentText id="alert-dialog-description" sx={{ fontSize: 20, color: '#000' }}>
                                        {t("Successful Registration")}<br />
                                        {t("A confirmation e-mail was sent to the registered e-mail address.")} <br />
                                        {t("Click on the link in this e-mail to validate your registration.")}
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={() => navigate("/login")}>
                                        {t("Return to the login page")}
                                    </Button>
                                </DialogActions>
                            </div>
                        )}

                        {(open && error) && (
                            <div>
                                <DialogContent
                                    sx={{
                                        textAlign: 'center',
                                        margin: 10
                                    }}
                                >
                                    <CancelOutlinedIcon
                                        color='error'
                                        sx={{
                                            fontSize: 100,
                                            marginBottom: 2
                                        }}
                                    />
                                    <DialogContentText id="alert-dialog-description" sx={{ fontSize: 18, color: '#000' }}>
                                        {t("Occured an Error")}<br />
                                        {t(error)}
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleClose}>
                                        {t("Close")}
                                    </Button>
                                </DialogActions>
                            </div>
                        )}
                    </Dialog>

                    {isSubmitting ? (
                        <Box display="flex" justifyContent="center" my={6}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <form onSubmit={handleSubmit}>
                            <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 4 }}>
                                <Grid item md={6} sm={12} xs={12}>
                                    <TextField
                                        name="nomeRepresentante"
                                        label={`1. ${t("Farm's Legal Representative")}`}
                                        value={values.nomeRepresentante}
                                        error={Boolean(touched.nomeRepresentante && errors.nomeRepresentante)}
                                        fullWidth
                                        helperText={touched.nomeRepresentante && errors.nomeRepresentante}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        variant="outlined"
                                    />
                                    <FormHelperText>{t("Name of the property responsible.")}</FormHelperText>
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                    <TextField
                                        name="nomeFazenda"
                                        label={`2. ${t("Farm's Name")}`}
                                        value={values.nomeFazenda}
                                        error={Boolean(touched.nomeFazenda && errors.nomeFazenda)}
                                        fullWidth
                                        helperText={touched.nomeFazenda && errors.nomeFazenda}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        variant="outlined"
                                    />
                                    <FormHelperText>{t("This is the name that would be shown in your contest certificate.")}</FormHelperText>
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                    <TextField
                                        id="pais"
                                        name="pais"
                                        value={paisValue}
                                        label={`3. ${t("Country")}`}
                                        onBlur={handleBlur}
                                        onChange={value => setPaisValue(value.target.value)}
                                        error={Boolean(touched.pais && errors.pais)}
                                        helperText={touched.pais && errors.pais}
                                        my={2}
                                        select
                                        fullWidth
                                        required
                                        defaultValue=""
                                    >
                                        {paises.map((item, index) => (
                                            <MenuItem key={index} value={item.name}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                {paisValue === 'Brasil' &&
                                    <Grid item md={6} sm={12} xs={12}>
                                        <InputMask
                                            mask="99999-999"
                                            value={values.cep}
                                            disabled={false}
                                            maskChar=""
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            required
                                        >
                                            {() => (
                                                <TextField
                                                    name="cep"
                                                    label={`4. ${t("Zip-Code")}`}
                                                    error={Boolean(touched.cep && errors.cep)}
                                                    fullWidth
                                                    helperText={touched.cep && errors.cep}
                                                    variant="outlined"
                                                />
                                            )}
                                        </InputMask>
                                    </Grid>
                                }
                                <Grid item md={6} sm={12} xs={12}>
                                    <TextField
                                        value={values.estado}
                                        error={Boolean(touched.estado && errors.estado)}
                                        helperText={touched.estado && errors.estado}
                                        name="estado"
                                        label={`5. ${t("State")}`}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        fullWidth
                                        my={2}
                                        required
                                    />
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                    <TextField
                                        value={values.cidade}
                                        error={Boolean(touched.cidade && errors.cidade)}
                                        helperText={touched.cidade && errors.cidade}
                                        name="cidade"
                                        label={`6. ${t("City")}`}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        fullWidth
                                        my={2}
                                        required
                                    />
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                    <TextField
                                        id="tipoDocumento"
                                        name="tipoDocumento"
                                        value={tipoDocumentoValue}
                                        label={`7. ${t("ID Document Type")}`}
                                        onBlur={handleBlur}
                                        onChange={value => setTipoDocumentoValue(value.target.value)}
                                        error={Boolean(
                                            touched.tipoDocumento && errors.tipoDocumento
                                        )}
                                        helperText={
                                            touched.tipoDocumento && errors.tipoDocumento
                                        }
                                        my={2}
                                        select
                                        fullWidth
                                        required
                                        defaultValue=""
                                    >
                                        {paisValue === 'Brasil' ?
                                            tipoDocBrasilProdutor.map((item, index) => (
                                                <MenuItem key={index} value={item}>
                                                    {t(item)}
                                                </MenuItem>
                                            )) :
                                            tipoDocInternacionalProdutor.map((item, index) => (
                                                <MenuItem key={index} value={item}>
                                                    {t(item)}
                                                </MenuItem>
                                            ))}
                                    </TextField>
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                    {paisValue === 'Brasil' ?
                                        <InputMask
                                            mask={tipoDocumentoValue === 'CPF' ?
                                                '999.999.999-99' : "99.999.999/0001-99"}
                                            value={values.numeroDocumento}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            disabled={false}
                                            maskChar=""
                                        >
                                            {() => (
                                                <TextField
                                                    name="numeroDocumento"
                                                    label={`8. ${t("Document Number")}`}
                                                    error={Boolean(touched.numeroDocumento && errors.numeroDocumento)}
                                                    fullWidth
                                                    helperText={touched.numeroDocumento && errors.numeroDocumento}
                                                    variant="outlined"
                                                    required
                                                />
                                            )}
                                        </InputMask> :
                                        <TextField
                                            name="numeroDocumento"
                                            value={values.numeroDocumento}
                                            error={Boolean(touched.numeroDocumento && errors.numeroDocumento)}
                                            helperText={touched.numeroDocumento && errors.numeroDocumento}
                                            label={`8. ${t("Document Number")}`}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            inputProps={tipoDocumentoValue === 'Passaporte' ? { maxLength: 9 } : { maxLength: 12 }}
                                            fullWidth
                                            my={2}
                                            required
                                        />
                                    }
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                    <InputMask
                                        mask={paisValue === 'Brasil' ? "(99) 99999-9999" : '(+99) 999-999-9999'}
                                        value={values.celular}
                                        disabled={false}
                                        maskChar=""
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                    >
                                        {() => (
                                            <TextField
                                                name="celular"
                                                label={`9. ${t("Cellphone Number")}`}
                                                value={values.celular}
                                                error={Boolean(touched.celular && errors.celular)}
                                                fullWidth
                                                helperText={touched.celular && errors.celular}
                                                variant="outlined"
                                            />
                                        )}
                                    </InputMask>
                                </Grid>
                                {paisValue === 'Brasil' &&
                                    <Grid item md={6} sm={12} xs={12}>
                                        <InputMask
                                            mask={paisValue === 'Brasil' ? "(99) 9999-9999" : '(+99) 99-999-999-999'}
                                            value={values.telefone}
                                            disabled={false}
                                            maskChar=""
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        >
                                            {() => (
                                                <TextField
                                                    name="telefone"
                                                    label={`10. ${t("Phone Number")}`}
                                                    value={values.telefone}
                                                    error={Boolean(touched.telefone && errors.telefone)}
                                                    fullWidth
                                                    helperText={touched.telefone && errors.telefone}
                                                    variant="outlined"

                                                />
                                            )}
                                        </InputMask>
                                    </Grid>
                                }
                                <Grid item md={6} sm={12} xs={12}>
                                    <TextField
                                        name="email"
                                        label={`11. ${t("E-mail")}`}
                                        value={values.email}
                                        error={Boolean(touched.email && errors.email)}
                                        fullWidth
                                        helperText={touched.email && errors.email}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                    <TextField
                                        name="site"
                                        label={`12. ${t("Website")}`}
                                        value={values.site}
                                        error={Boolean(touched.site && errors.site)}
                                        fullWidth
                                        helperText={touched.site && errors.site}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item md={12}>
                                    <SenhaInput
                                        id="senha"
                                        name="senha"
                                        label={t("Password")}
                                        value={values.senha}
                                        error={Boolean(touched.senha && errors.senha)}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                    />
                                    <FormHelperText>
                                        {t("The password must have at least 10 characters, with at least one uppercase letter, one lowercase letter and one number.")}
                                    </FormHelperText>
                                </Grid>
                                <Grid item md={12}>
                                    <FormControlLabel
                                        required
                                        control={<Checkbox />}
                                        checked={checked}
                                        onChange={value => setChecked(value.target.checked)}
                                        label={t("I have read and agreed to the Terms and Contest Regulation.")}
                                    />
                                    <br /><a href="https://expoqueijobrasil.com.br/concurso/regulamento/" target="_blank" style={{ color: '#000', textDecoration: 'underline' }}>{t("Click here to read the Terms and Contest Regulation.")}</a>
                                </Grid>
                                <Grid
                                    item
                                    container
                                    direction="row"
                                    justifyContent="flex-end"
                                >
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                    >
                                        {t("Register")}
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </React.Fragment>
            )}
        </Formik>
    );
}

export default FormularioProdutor;