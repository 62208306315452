import React from "react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import AgricultureOutlinedIcon from '@mui/icons-material/AgricultureOutlined';
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined';
import {
  Button,
  Typography,
  Grid
} from "@mui/material";
import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(6)};
  text-align: center;
  background: transparent;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function Cadastro() {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Helmet title="Escolha o seu tipo de cadastro" />
      <Typography component="h1" variant="h3" align="center" sx={{ marginBottom: 5 }}>
        {t("Choose your role")}
      </Typography>
      <Typography component="h1" variant="h4" align="center" sx={{ marginBottom: 5 }}>
        {t("I'm a...")}
      </Typography>
      <Grid container spacing={6} className='escolha-tipo-usuario'>
        <Grid item xs={6}>
          <Button
            component={Link}
            to="/cadastro-produtor"
            fullWidth
            variant="contained"
            color="primary"
            sx={{
              padding: '40px 10px',
              display: 'block',
              background: '#ddb325',
              minWidth: 150
            }}
          >
            <AgricultureOutlinedIcon />
            <Typography sx={{ fontSize: 16, marginTop: 2 }}>{t("Cheese Maker")}</Typography>
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            component={Link}
            to="/cadastro-jurado"
            fullWidth
            variant="contained"
            color="primary"
            sx={{
              padding: '40px 10px',
              display: 'block',
              background: '#ddb325',
              minWidth: 150
            }}
          >
            <RateReviewOutlinedIcon />
            <Typography sx={{ fontSize: 16, marginTop: 2 }}>{t("Cheese Judge")}</Typography>
          </Button>
        </Grid>
      </Grid>
    </Wrapper>
  );
}

export default Cadastro;