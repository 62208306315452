import React, { useState } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import styled from "@emotion/styled";
import * as Yup from "yup";
import { Formik } from "formik";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { alteracaoSenhaUsuario } from "../../api/usuarios";
import {
  Alert as MuiAlert,
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
  Box,
  CircularProgress,
  TextField as MuiTextField,
  FormHelperText,
} from "@mui/material";
import { spacing } from "@mui/system";
import useAuth from "../../hooks/useAuth";
import { useTranslation } from "react-i18next";
import SenhaInput from "../../components/forms/Senha/SenhaInput";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

function AlteracaoSenha() {
  const { t } = useTranslation();
  const theme = useTheme();
  const location = useLocation()
  const navigate = useNavigate();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [error, setError] = useState("");
  const [sucesso, setSucesso] = useState(false);
  const [open, setOpen] = useState(false);
  const { resetPassword } = useAuth();

  const handleSubmit = async (
    values,
    { setErrors, setStatus, setSubmitting }
  ) => {
    try {
      const params = new URLSearchParams(location.search)

      debugger;
      await alteracaoSenhaUsuario(values, params.get("email"), params.get("token"))

      setSucesso(true);
      setStatus({ sent: true });
      setSubmitting(false);
      openAlert();
    } catch (error) {
      setStatus({ sent: false });
      setError(error.notificacoes[0].message);
      setSubmitting(false);
      openAlert();
    }
  }

  const handleClose = () => {
    setError('');
    setOpen(false);
  };

  const openAlert = () => {
    setOpen(true);
  };

  return (
    <Formik
      initialValues={{
        senha: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        senha: Yup.string()
          .required("Campo obrigatória")
          .min(10, 'Senha curta demais - deve conter ao menos 10 caracteres.')
          .minLowercase(1, 'A senha deve conter ao menos uma letra em caixa baixa.')
          .minUppercase(1, 'A senha deve conter ao menos uma letra em caixa alta.')
          .minNumbers(1, 'A senha deve conter ao menos um número.'),
      })}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <React.Fragment>
          <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
            className='alert-dialog'
          >
            {(open && sucesso) &&
              <div>
                <DialogContent
                  sx={{
                    textAlign: 'center',
                    margin: 10
                  }}
                >
                  <CheckCircleOutlineIcon
                    color='success'
                    sx={{
                      fontSize: 100,
                      marginBottom: 2
                    }}
                  />
                  <DialogContentText sx={{ fontSize: 22, color: '#000' }}>
                    {t("Password changed successfully")}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => navigate("/login")}>
                    {t("Return to the login page")}
                  </Button>
                </DialogActions>
              </div>
            }

            {(open && !sucesso && error) &&
              <div>
                <DialogContent
                  sx={{
                    textAlign: 'center',
                    margin: 10
                  }}
                >
                  <CancelOutlinedIcon
                    color='error'
                    sx={{
                      fontSize: 100,
                      marginBottom: 2
                    }}
                  />
                  <DialogContentText sx={{ fontSize: 20, color: '#000' }}>
                    <span sx={{ fontSize: 22 }}>
                      {t("Occured an Error")}
                    </span>
                    <br />
                    <span sx={{ fontSize: 18 }}>{error.email}</span>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>
                    {t("Close")}
                  </Button>
                </DialogActions>
              </div>
            }
          </Dialog>

          {isSubmitting ? (
            <Box display="flex" justifyContent="center" my={6}>
              <CircularProgress />
            </Box>
          ) : (
            <form noValidate onSubmit={handleSubmit}>
              {errors.submit && (
                <Alert mt={2} mb={1} severity="warning">
                  {errors.submit}
                </Alert>
              )}
              <Grid container spacing={2}>
                <div>
                  <Typography variant="h3" gutterBottom>
                    {t("New Password")}
                  </Typography>
                  <Typography sx={{ marginBottom: 5, marginTop: 5 }}>{t("Type your new password below.")}</Typography>
                </div>
                <SenhaInput
                  id="senha"
                  name="senha"
                  label={t("Password")}
                  value={values.senha}
                  error={Boolean(touched.senha && errors.senha)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <FormHelperText>
                  {t("The password must have at least 10 characters, with at least one uppercase letter, one lowercase letter and one number.")}
                </FormHelperText>

                <Grid item xs={12} sx={{ display: 'grid' }}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                    {t("Change password")}
                  </Button>
                </Grid>
                <Grid item xs={12} sx={{ display: 'grid' }}>
                  <Button
                    component={Link}
                    to="/login"
                    color="primary"
                  >
                    {t("Back")}
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        </React.Fragment>
      )}
    </Formik>
  );
}
export default AlteracaoSenha;