import React from 'react';
import { Typography } from "@mui/material";
import FormularioProdutor from './Formularios/FormularioProdutor';
import { useTranslation } from "react-i18next";

function CadastroProdutor() {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Typography
        component="h3"
        variant="h3"
        align="center"
        sx={{
          marginBottom: 10
        }}
      >
        {t("Cheese Maker Registration")}
      </Typography>
      <FormularioProdutor />
    </React.Fragment>
  );
}
export default CadastroProdutor;