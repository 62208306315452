import React from "react";
import styled from "@emotion/styled";
import { Paper } from "@mui/material";
import EsqueciMinhaSenhaComponent from "../../components/auth/EsqueciMinhaSenha";

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};
  border-radius: 15px;
  margin: 20px 0;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function EsqueciMinhaSenha() {
  return (
    <Wrapper>
      <EsqueciMinhaSenhaComponent />
    </Wrapper>
  );
}

export default EsqueciMinhaSenha;