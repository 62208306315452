import axios from "../utils/axios";

export function inscreverQueijo(data) {
    return axios.post(`/api/inscricoes`, data)
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            throw error;
        });
}

export function exportarInscricoesQueijo() {
    return axios.get(`/api/inscricoes/exportar`);
}

export function listarInscricoesQueijo(produtor, codigo) {
    let url = `/api/inscricoes`;

    if (produtor && codigo)
        url = url + `?produtor=${produtor}&codigo=${codigo}`;
    if (produtor && !codigo)
        url = url + `?produtor=${produtor}`;
    if (codigo && !produtor)
        url = url + `?codigo=${codigo}`;

    return axios.get(url);
}

export function obterInscricaoQueijo(id) {
    return axios.get(`/api/inscricoes/${id}`)
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            throw error;
        });
}

export function atualizarInscricaoQueijo(id, data) {
    return axios.put(`/api/inscricoes/${id}`, data)
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            throw error;
        });
}

export function listarIncricoesQueijoProdutor(usuarioId) {
    return axios.get(`/api/inscricoes/usuarios/${usuarioId}`);
}

export function deletarInscricaoQueijo(id) {
    return axios.delete(`/api/inscricoes/${id}`);
}

export function deletarCertificadoInscricaoQueijo(id) {
    return axios.delete(`/api/inscricoes/${id}/certificados`);
}

export function receberProduto(id, data) {
    return axios.put(`/api/inscricoes/${id}/recebimentos`, data)
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            throw error;
        });
}

export function validarProduto(id, data) {
    return axios.put(`/api/inscricoes/${id}/validacoes`, data)
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            throw error;
        });
}

export function separarProduto(id, data) {
    return axios.put(`/api/inscricoes/${id}/separacoes`, data)
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            throw error;
        });
}

export function getPremiacaoQueijo(id, ordemFase) {
    return axios.get(`/api/inscricoes/${id}/premiacoes/${ordemFase}`);
}