import { Global, css } from "@emotion/react";
import BackgroundCertificadoJurado from "../images/certificado_jurado.jpg";
import BackgroundCertificadoQueijoSuperOuro from "../images/certificado_superouro.jpg";
import BackgroundCertificadoQueijoOuro from "../images/certificado_ouro.jpg";
import BackgroundCertificadoQueijoPrata from "../images/certificado_prata.jpg";
import BackgroundCertificadoQueijoBronze from "../images/certificado_bronze.jpg";

const GlobalStyle = (props) => (
  <Global
    {...props}
    styles={css`
      html,
      body,
      #root {
        height: 100%;
      }

      body {
        margin: 0;
      }

      .MuiCardHeader-action .MuiIconButton-root {
        padding: 4px;
        width: 28px;
        height: 28px;
      }

      body > iframe {
        pointer-events: none;
      }

      a {
        margin: 5px 0;
      }
      a:hover {
        color: #bd9717;
        background-color: rgba(221, 179, 37, 0.15);
      }

      .escolha-tipo-usuario a:hover {
        color: #fff;
      }

      button:focus {
        outline: none;
      }

      .action-dialog .MuiPaper-root {
        max-width: unset;
      }

      // .alert-dialog .MuiDialog-paper p {
      //   margin-top: 20px;
      // }

      .hzero-brand {
        bottom: 0;
        max-width: 100% !important;
        justify-content: center !important;
      }

      @media (min-width: 1080px) {
        .authLogo {
          height: 100px;
        }
      }

      p.MuiTablePagination-selectLabel,
      p.MuiTablePagination-displayedRows {
        margin-top: unset;
        margin-bottom: unset;
      }

      .lista-impressao .bordered-table td {
        border: 1px solid;
        padding: 5px;
      }

      .lista-impressao .dashed-table tr {
        border-style: dashed;
        padding: 5px;
      }

      .classificacao-chip span,
      .classificacao-chip svg {
        color: rgba(0, 0, 0, 0.87) !important;
        font-weight: bold;
      }

      .MuiTableCell-root .MuiBox-root {
        display: flex;
        float: right;
      }

      .judge-select .MuiDialog-paper {
        max-width: 1300px;
      }

      .filtro .MuiFormControl-root {
        margin-bottom: unset;
        margin-top: unset;
      }

      .certificado {
        font-family: 'Cocogoose';
        padding: 30px;
        width: 1200px;
        margin-top: 20px;
        height: 800px;
        box-shadow: 10px 5px 25px #bcbcbc;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 45%;
        font-weight: lighter;
      }

      .certificado.jurado {
        background-image: url(${BackgroundCertificadoJurado});
      }

      .certificado.queijo.super-ouro {
        background-image: url(${BackgroundCertificadoQueijoSuperOuro});
      }

      .certificado.queijo.super-ouro .titulo-certificado,
      .certificado.queijo.super-ouro .classificacao-certificado {
        display: none;
      }

      .certificado.queijo.super-ouro .nome-queijo {
        margin-top: 170px;
      }

      .certificado.queijo.ouro {
        background-image: url(${BackgroundCertificadoQueijoOuro});
      }

      .certificado.queijo.prata {
        background-image: url(${BackgroundCertificadoQueijoPrata});
      }

      .certificado.queijo.bronze {
        background-image: url(${BackgroundCertificadoQueijoBronze});
      }

      .certificado p {
        margin: 0;
      }

      .certificado .corpo-certificado {
        font-size: 1.25rem;
        width: 100%;
        text-align: center;
      }

      .certificado.jurado .corpo-certificado {
        margin-top: 180px;
      }

      .certificado.queijo .corpo-certificado {
        margin-top: 130px;
      }

      .certificado .titulo-certificado {
        line-height: normal;
        font-size: 2.8rem;
        font-weight: bold;
      }

      .certificado.queijo .titulo-certificado {
        font-size: 3rem;
      }

      .certificado .classificacao-certificado {
        line-height: normal;
        font-size: 2.1rem;
        margin-top: 10px;
      }

      .certificado .classificacao-ingles {
        margin-left: 10px;
        font-weight: 300;
      }

      .certificado .nome-certificado {
        margin-top: 25px;
        font-size: 2.8rem; 
      }

      .certificado .nome-queijo,
      .certificado .nome-fazenda {
        font-size: 2rem; 
      }

      .certificado .nome-queijo {
        margin-top: 50px;
      }

      .certificado .nome-queijo.categoria-grande {
        margin-top: 30px;
      }

      .certificado.jurado .nome-certificado {
        text-transform: uppercase; 
      }

      .certificado .descricao-certificado {
        margin-top: 25px;
        width: 100%;
        text-align: center;
      }

      .certificado.queijo .descricao-certificado {
        font-family: 'GillSans';
        margin-top: 30px;
        font-size: 1.2rem;
        font-weight: bold;
      }

      .dashboard button,
      .dashboard a[class^="MuiButton"] {
        width: 100%
      }

      @media (max-width: 576px) {
        .dashboard button,
        .dashboard a[class^="MuiButton"] {
          height: 100px;
          font-size: 1.5rem;
        }
        .dashboard button svg,
        .dashboard a[class^="MuiButton"] svg {
          font-size: 1.5rem;
        }
      }
      }

      .relatorio-avaliacao .relatorio-info-produto {
        margin-top: 30px;
        font-size: 14pt;
        width: 100%;
      }

    `}
  />
);

export default GlobalStyle;
