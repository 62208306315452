function createShadow() {
    return `box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);`;
  }
  
  const shadows = [
    "none",
    createShadow(),
    createShadow(),
    createShadow(),
    createShadow(),
    createShadow(),
    createShadow(),
    createShadow(),
    createShadow(),
    createShadow(),
    createShadow(),
    createShadow(),
    createShadow(),
    createShadow(),
    createShadow(),
    createShadow(),
    createShadow(),
    createShadow(),
    createShadow(),
    createShadow(),
    createShadow(),
    createShadow(),
    createShadow(),
    createShadow(),
    createShadow(),
  ];
  
  export default shadows;
  