import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import jwtDecode from "jwt-decode";

const resources = {
  en: {
    translation: {
      Search: "Zoeken…",
      "Welcome back": "Welcome back",
      "Yes": "Yes",
      "No": "No",
      "Dashboard": "Dashboard",
      "We've missed you": "We've missed you",
      "Users": "Users",
      "User": "User",
      "user": "user",
      "cheese judge": "cheese judge",
      "cheese maker": "cheese maker",
      "Edit User": "Edit User",
      "Edit Cheese Maker": "Edit Cheese Maker",
      "Edit Cheese Judge": "Edit Cheese Judge",
      "Add User": "Add User",
      "Add Cheese Maker": "Add Cheese Maker",
      "Add Cheese Judge": "Add Cheese Judge",
      "Name": "Name",
      "Full Name": "Full Name",
      "Lastname": "Lastname",
      "E-mail": "E-mail",
      "Language": "Language",
      "Role": "Role",
      "Actions": "Actions",
      "New User": "New User",
      "Save": "Save",
      "Save Changes": "Save Changes",
      "Cheese Maker": "Cheese Maker",
      "Cheese Judge": "Cheese Judge",
      "Produtor": "Cheese Maker",
      "Jurado": "Cheese Judge",
      "Cheese Judges": "Cheese Judges",
      "Admin": "Admin",
      "Administrador": "Admin",
      "Password": "Password",
      "Confirm Password": "Confirm Password",
      "The password must have at least 10 characters, with at least one uppercase letter, one lowercase letter and one number.": "The password must have at least 10 characters, with at least one uppercase letter, one lowercase letter and one number.",
      "Portuguese": "Portuguese",
      "English": "English",
      "Spanish": "Spanish",
      "Italian": "Italian",
      "French": "French",
      "Close": "Close",
      "Occured an Error": "Occured an Error",
      "Done": "Done",
      "User registred successfully": "User registred successfully",
      "Required field": "Required field",
      "User updated successfully": "User updated successfully",
      "Cheese subscribed successfully": "Cheese subscribed successfully",
      "Cheese updated successfully": "Cheese updated successfully",
      "Farm's Name": "Farm's Name",
      "Farm's Legal Representative": "Farm's Legal Representative",
      "Country": "Country",
      "State": "State",
      "Status": "Status",
      "Active": "Active",
      "Inactive": "Inactive",
      "Cheese Makers": "Cheese Makers",
      "Cheese Judges": "Cheese Judges",
      "User agreed with the terms and Contest Regulation.": "User agreed with the terms and Contest Regulation.",
      "I have read and agreed to the Terms and Contest Regulation.": "I have read and agreed to the Terms and Contest Regulation.",
      "Every term and contest regulation have been agreed by the user.": "Every term and contest regulation have been agreed by the user.",
      "Website": "Website",
      "Phone Number": "Phone Number",
      "Cellphone Number": "Cellphone Number",
      "Document Number": "Document Number",
      "RG": "RG",
      "CPF": "CPF",
      "CNPJ": "CNPJ",
      "Passport": "Passport",
      "ID Document Type": "ID Document Type",
      "City": "City",
      "Zip-Code": "Zip-Code",
      "This is the name that would be shown in your contest certificate.": "This is the name that would be shown in your contest certificate.",
      "Name of the property responsible.": "Name of the property responsible.",
      "Cheese Maker updated successfully": "Cheese Maker updated successfully",
      "Cheese Judge updated successfully": "Cheese Judge updated successfully",
      "Curator updated successfully": "Curator updated successfully",
      "Curator registered successfully": "Curator registered successfully",
      "Cheese Maker registered successfully": "Cheese Maker registered successfully",
      "Cheese Judge registered successfully": "Cheese Judge registered successfully",
      "A confirmation e-mail was sent to the registered e-mail address.": "A confirmation e-mail was sent to the registered e-mail address.",
      "This cheese maker needs to be activated by the user.": "This cheese maker needs to be activated by the user.",
      "This cheese judge needs to be activated by the user.": "This cheese judge needs to be activated by the user.",
      "Cheese Maker Registration": "Cheese Maker Registration",
      "Cheese Judge Registration": "Cheese Judge Registration",
      "New Cheese Maker": "New Cheese Maker",
      "New Cheese Judge": "New Cheese Judge",
      "Subscribe another cheese": "Subscribe another cheese",
      "Subscribe new cheese": "Subscribe new cheese",
      "Edit Cheese Subscription": "Edit Cheese Subscription",
      "Go to payment": "Go to payment",
      "Cheese Name": "Cheese Name",
      "Certificate Type": "Certificate Type",
      "Municipal Certificate": "Municipal Certificate",
      "State Certificate": "State Certificate",
      "Federal Certificate": "Federal Certificate",
      "Municipal": "Municipal Certificate",
      "Estadual": "State Certificate",
      "Federal": "Federal Certificate",
      "Other": "Other",
      "Selo Arte": "Art Stamp",
      "Art Stamp": "Art Stamp",
      "Certificate: Attach the cheese's certificate": "Certificate: Attach the cheese's certificate",
      "Cheese's Characteristics": "Cheese's Characteristics",
      "Category": "Category",
      "Origin": "Origin",
      "Milk Type": "Milk Type",
      "Raw": "Raw",
      "Pasteurized": "Pasteurized",
      "Manufacturing Date": "Manufacturing Date",
      "Dough Treatment": "Dough Treatment",
      "Rind Type": "Rind Type",
      "Cheese Subscriptions": "Cheese Subscriptions",
      "Cheese Subscription": "Cheese Subscription",
      "New Cheese Subscription": "New Cheese Subscription",
      "Certificate": "Certificate",
      "Code": "Code",
      "Paid": "Paid",
      "Subscribed Cheeses": "Subscribed Cheeses",
      "Cheeses": "Cheeses",
      "Subscriptions": "Subscriptions",
      "Orders": "Orders",
      "Orders History": "Orders History",
      "Reviews": "Reviews",
      "Administrative": "Administrative",
      "Brazil": "Brazil",
      "Italy": "Italy",
      "United States": "United States",
      "France": "France",
      "Germany": "Germany",
      "Spain": "Spain",
      "Approved": "Approved",
      "Disapproved": "Disapproved",
      "Pending": "Pending",
      "Approval": "Approval",
      "Occupation": "Occupation",
      "Rows per page:": "Rows per page:",
      "of": "of",
      "more than": "more than",
      "Proficiency Languages": "Proficiency Languages",
      "List every language you have proficiency.": "List every language you have proficiency.",
      "Male": "Male",
      "Female": "Female",
      "Other": "Other",
      "Nationality": "Nationality",
      "Gender": "Gender",
      "Educational Background": "Educational Background",
      "Professional Activity": "Professional Activity",
      "Company": "Company",
      "Cheese sensory analysis experience": "Cheese sensory analysis experience",
      "I've never been a cheese contest judge": "I've never been a cheese contest judge",
      "Are there any restrictions on judging a certain type of cheese?": "Are there any restrictions on judging a certain type of cheese?",
      "Describe your dietary restriction": "Describe your dietary restriction",
      "Cart": "Cart",
      "Add File": "Add File",
      "Delete": "Delete",
      "Are you sure that you want to delete": "Are you sure that you want to delete",
      "deleted successfully!": "deleted successfully!",
      "Item deleted successfully!": "Item deleted successfully!",
      "Cart": "Cart",
      "Description": "Description",
      "Amount": "Amount",
      "Go to payment": "Go to payment",
      "Items": "Items",
      "Remove": "Remove",
      "Order Summary": "Order Summary",
      "Contest Parameters": "Contest Parameters",
      "Parameters updated successfully!": "Parameters updated successfully!",
      "Contest Subscripition End Date": "Contest Subscripition End Date",
      "Contest Subscripition Start Date": "Contest Subscripition Start Date",
      "Payment Expiring Date": "Payment Expiring Date",
      "Cheese Subscription Cost (R$)": "Cheese Subscription Cost (R$)",
      "Contest Subscription Cost (R$)": "Contest Subscription Cost (R$)",
      "Logout": "Logout",
      "ID da Propriedade": "Property ID",
      "Passaporte": "Passport",
      "Approve": "Approve",
      "Disapprove": "Disapprove",
      "Success!": "Success!",
      "Warning!": "Warning!",
      "Invoices": "Invoices",
      "You still didn't completed your subscription in the Expoqueijo's 2024 International Cheese Awards!": "You still didn't completed your subscription in the Expoqueijo's 2024 International Cheese Awards!",
      "Finish you subscription clicking in the button below and make the payment of your subscription.": "Finish you subscription clicking in the button below and make the payment of your subscription.",
      "Order number": "Order number",
      "Order total": "Order total",
      "Thank you for your subscription.": "Thank you for your subscription.",
      "Cash Credit Card": "Cash Credit Card",
      "Credit card in": "Credit card in",
      "installments": "installments",
      "Pagamento realizado com sucesso!": "Successful payment!",
      "Credit Card": "Credit Card",
      "Payment method": "Payment method",
      "YOUR NAME": "YOUR NAME",
      "Card Number": "Card Number",
      "Card Name": "Card Name",
      "Valid Thru": "Valid Thru",
      "Installments": "Installments",
      "Finish Order": "Finish Order",
      "This is a safe environment, payment made in encrypted environment.": "This is a safe environment, payment made in encrypted environment.",
      "Finish Payment": "Finish Payment",
      "Válido até às": "Valid thru",
      "Brasilia time.": "Brasilia time.",
      "Copy Pix Code": "Copy Pix Code",
      "Payment Info": "Payment Info",
      "Pix à vista": "Pix",
      "Boleto à vista": "Boleto",
      "Order": "Order",
      "Payment Status": "Payment Status",
      "Payment Date": "Payment Date",
      "Value": "Value",
      "Pago": "Paid",
      "Vencida": "Expired",
      "Aguardando pagamento": "Waiting for payment",
      "Expiring Date": "Expiring Date",
      "Expired in": "Expired in",
      "Print": "Print",
      "Payment No.": "Payment No.",
      "Hello,": "Hello",
      "Este é o recibo do pagamento de": "This is the receipt for a payment of",
      "feito para Expoqueijo Brasil.": "made to Expoqueijo Brasil.",
      "to Expoqueijo Brasil.": "to Expoqueijo Brasil",
      "Este é o recibo vencido de um pedido de": "This is an expired receipt of an order of",
      "Este é o recibo de um pagamento pendente no valor de": "This is a pending payment receipt for an order of",
      "Order Completed": "Order Completed",
      "Inscricao": "Awards Subscription",
      "Produto": "Cheese Subscription",
      "Log In": "Log In",
      "Forgot your password?": "Forgot your password?",
      "Sign up": "Sign up",
      "Remember me": "Remember me",
      "Back": "Back",
      "Change my password": "Change my password",
      "Type your e-mail address to receive the instructions to setup your new password.": "Type your e-mail address to receive the instructions to setup your new password.",
      "Back to login": "Back to login",
      "We just sent you the e-mail with the instructions to change your password.": "We just sent you the e-mail with the instructions to change your password.",
      "Didn't received the email?": "Didn't received the email?",
      "Check your trash or spam inboxes": "Check your trash or spam inboxes",
      "Check you typed correctly your registred e-mail address": "Check you typed correctly your registred e-mail address",
      "Make sure that the e-mail address is registred in our database": "Make sure that the e-mail address is registred in our database",
      "If you still have truble to access your account, contact our support.": "If you still have truble to access your account, contact our support.",
      "Choose your role": "Choose your role",
      "I'm a...": "I'm a...",
      "Paying Method": "Paying Method",
      "CartaoCredito": "Credit Card",
      "Order Details": "Order Details",
      "Click on the link in this e-mail to validate your registration.": "Click on the link in this e-mail to validate your registration.",
      "Return to the login page": "Return to the login page",
      "Experience details:": "Experience details:",
      "Register": "Register",
      "Successful Registration": "Successful Registration",
      "E-mail inválido": "Invalid e-mail",
      "Campo obrigatório": "Mandatory field",
      "Type your new password below.": "Type your new password below.",
      "New Password": "New Password",
      "Password changed successfully": "Password changed successfully",
      "Change password": "Change password",
      "You must attach the certificate.": "You must attach the certificate.",
      "Cannot read properties of undefined (reading 'url')": "You must attach the certificate.",
      "Do you really want to delete the file": "Do you really want to delete the file",
      "Delete file": "Delete file",
      "The certificate is required": "The certificate is required",
      "days": "days",
      "Product Information": "Product Information",
      "Cheese Review Sheet": "Cheese Review Sheet",
      "Maturation Days": "Maturation Days",
      "Product's Code": "Product's Code",
      "Estragado": "Expired",
      "Péssimo": "Terrible",
      "Ruim": "Bad",
      "Insuficiente": "Not enough",
      "Razoável": "Ordinary",
      "Suficiente": "Enough",
      "Bom": "Good",
      "Ótimo": "Great",
      "Excelente": "Excellent",
      "Perfeito": "Perfect",
      "Send Review": "Send Review",
      "General Aspect": "General Aspect",
      "Color": "Color",
      "Texture": "Texture",
      "Odor": "Odor",
      "Scent": "Scent",
      "Consistency": "Consistency",
      "Flavor": "Flavor",
      "Payment": "Payment",
      "Title / Aspect": "Title / Aspect",
      "Title": "Title",
      "Aspect": "Aspect",
      "Max Rating": "Max Rating",
      "Weight": "Weight",
      "Add New Evaluation Field": "Add New Evaluation Field",
      "Click here to read the Terms and Contest Regulation.": "Click here to read the Terms and Contest Regulation.",
      "Cheeses": "Cheeses",
      "Cheese": "Cheese",
      "Subscription": "Subscription",
      "Cancel": "Cancel",
      "Add": "Add",
      "The user read and agreed with the contest terms and regulation": "The user read and agreed with the contest terms and regulation",
      "Check your e-mail inbox, and if you don't find the e-mail there, kindly take a look at your spam or junk folder.": "Check your e-mail inbox, and if you don't find the e-mail there, kindly take a look at your spam or junk folder.",
      "User not found.": "User not found.",
      "Usuário ou senha inválidos!": "Invalid user or password!",
      "Queijo Maturado": "Aged",
      "Queijo de Untar, Espalhável (Ex. de passar no pão)": "Greasing Cheese",
      "Ricotta": "Ricotta",
      "Queijo Coalho": "Curd Cheese",
      "Queijo de Manteiga": "Butter Cheese",
      "Queijo Pastoso": "Pasty Cheese",
      "Queijo de Massa Fundida Requeijão em Barra": "Cottage Cheese",
      "Queijo de Massa Fundida Requeijão Moreno": "Moreno Cottage Cheese",
      "Requeijão em Barra": "Cottage Cheese",
      "Requeijão Moreno": "Moreno Cottage Cheese",
      "Queijo com Mofo Azul": "Blue Mold Cheese",
      "Queijo Aromatizado e/ou Condimentado": "Flavored and/or Spiced Cheese",
      "Fresco com tratamento (mofo ou condimento)": "Processed fresh cheese (mold and/or condiment)",
      "Fresco sem tratamento": "Unprocessed Fresh Cheese",
      "Casca Lisa e/ou Lavada (cascas sem presença de fungos, resinas e demais itens)": "Smooth or Washed Rind",
      "Casca Florida (casca com predomínio de fungos filamentosos)": "Flowered Rind",
      "Casca Tratada (exemplo: carvão vegetal; resinas; corantes, entre outros.)": "Treated Rind",
      "Com Aquecimento": "Heated",
      "Sem Aquecimento": "No heated",
      "Queijo Massa Filada": "Spliced Pasta",
      "Queijo Massa Não Filada": "Unspliced Pasta",
      "Cru": "Raw",
      "Pausterizado": "Pausterized",
      "Vaca": "Cow",
      "Cabra": "Goat",
      "Ovelha": "Sheep",
      "Búfala": "Buffalo",
      "Mistura de Diferentes Espécies": "Different Species Mix",
      "Outro": "Other",
      "Attention!": "Attention!",
      "Event": "Event",
      "Awards Info": "Awards Info",
      "Email não confirmado!": "E-mail not enabled!",
      "Resend account validation e-mail": "Resend account validation e-mail",
      "There are no items in your cart.": "There are no items in your cart.",
      "Contest Phase": "Contest Phase",
      "Contest Phases": "Contest Phases",
      "Max Grade": "Max Grade",
      "Add question": "Add question",
      "Contest Start Date": "Contest Start Date",
      "Contest": "Contest",
      "Manufacturing Date cannot be after the event initial date.": "Manufacturing Date cannot be after the event initial date.",
      "Payment Status": "Payment Status",
      "Processando pagamento": "Processing payment",
      "Não pago": "Not paid",
      "O pagamento não pôde ser concluído: O CPF ou CNPJ informado é inválido.": "The payment failed: Invalid CPF or CNPJ.",
      "cheese": "cheese",
      "Add phase": "Add phase",
      "Contest Phase Name": "Contest Phase Name",
      "Contest Phase Order": "Contest Phase Order",
      "Contest Tables": "Contest Tables",
      "Shuffle Tables": "Shuffle Tables",
      "Contest Table ID Code": "Contest Table ID Code",
      "Table Details": "Table Details",
      "Subscriptions by Makers": "Subscriptions by Makers",
      "Your Reviews": "Your Reviews",
      "New Curator": "New Curator",
      "Curator": "Curator",
      "Curators": "Curators",
      "Edit Curator": "Edit Curator",
      "Makers With No Subscriptions": "Makers With No Subscriptions",
      "Phone": "Phone",
      "Pending Payment": "Pending Payment",
      "Reports": "Reports",
      "Table": "Table",
      "Do you want to move": "Do you want to move",
      "to which table?": "to which table?",
      "Move judge to another table": "Move judge to another table",
      "Free": "Free",
      "Move": "Move",
      "Cheese Judge moved successfully": "Cheese Judge moved successfully",
      "Cheese moved successfully": "Cheese moved successfully",
      "Cheese reviewed successfully": "Cheese reviewed successfully",
      "Jurado sem acesso ao queijo!": "You are not allowed to review this cheese.",
      "Queijo já avaliado!": "Cheese already reviewed.",
      "Return": "Return",
      "Return to the previous page": "Return to the previous page",
      "Search": "Search",
      "Filter": "Filter",
      "Table QR Codes": "Table QR Codes",
      "Print Judges List": "Print Judges List",
      "Print Cheese's List": "Print Cheese's List",
      "Print QR Codes": "Print QR Codes",
      "Untie Priority": "Untie Priority",
      "Real Time": "Real Time",
      "Event in Real Time": "Event in Real Time",
      "Start Phase": "Start Phase",
      "End Phase": "End Phase",
      "Average Score": "Average Score",
      "Qtd. Reviewed": "Qtd. Reviewed",
      "Total Reviews": "Total Reviews",
      "Fase iniciada com sucesso!": "Contest Phase Started Successfully!",
      "Fase finalizada com sucesso!": "Contest Phase Finished Successfully!",
      "Phase already finished.": "Phase already finished.",
      "Reset Phase": "Reset Phase",
      "Phase": "Phase",
      "Your Score (%)": "Your Score (%)",
      'Average Score (%)': 'Average Score (%)',
      'Winner (%)': 'Winner (%)',
      "Category Average Score (%)": "Category Average Score (%)",
      "Overall Average Score (%)": "Overall Average Score (%)",
      "Awards Results": "Awards Results",
      "Overall Result": "Overall Result",
      "Category Result": "Category Result",
      "Score": "Score",
      "Awarded": "Awarded",
      "Qualified to Next Phase": "Qualified to Next Phase",
      "Awards Results by Category": "Awards Results by Category",
      "Export": "Export",
      "Classificados da 1ª fase": "Qualified to Second Phase",
      "Classificados da 2ª fase": "Qualified to Third Phase",
      "Qualified to Second Phase": "Qualified to Second Phase",
      "Qualified to Third Phase": "Qualified to Third Phase",
      "Qualified by Phase": "Qualified by Phase",
      "General": "General",
      "Second Phase": "Second Phase",
      "Approve to Second Phase": "Approve to Second Phase",
      "Cheese Judge approved to this contest phase!": "Cheese Judge approved to this contest phase!",
      "Cheeses Qualified by Phase": "Cheeses Qualified by Phase",
      "Tente novamente mais tarde.": "Try again later.",
      "Refresh": "Refresh",
      "Number of Cheeses by Table": "Number of Cheeses by Table",
      "Number of Cheese Judges by Table": "Number of Cheese Judges by Table",
      "Selected": "Selected",
      "Not selected": "Not selected",
      "Deselect": "Deselect",
      "Jurado aprovado na fase": "Cheese Judge approved to this phase.",
      "Jurado reprovado na fase": "Cheese Judge repproved to this phase.",
      "Quantitative Assessment Form": "Quantitative Assessment Form",
      "Ratings from 0 to ": "Ratings from 0 to ",
      "Rating from 0 to ": "Rating from 0 to ",
      "Selecionados": "Selected",
      "Select Judge": "Select Judge",
      "Add Cheese Judges - Phase": "Add Cheese Judges - Phase",
      "Export Grades": "Export Grades",
      "Reset Event": "Reset Event",
      "Reopen Phase": "Reopen Phase",
      "Are you sure that you want to reopen this phase?": "Are you sure that you want to reopen this phase?",
      "When you reopen a phase, you allow cheese judges to submit new reviews or overwrite their past reviews.": "When you reopen a phase, you allow cheese judges to submit new reviews or overwrite their past reviews.",
      "Select a Category": "Select a Category",
      'Please wait, exporting data...': 'Please wait, exporting data...',
      "Are you sure that you want to re-order the contest tables?": "Are you sure that you want to re-order the contest tables?",
      "When you re-oder the tables, you change all cheeses and judges from its previous tables.": "When you re-oder the tables, you change all cheeses and judges from its previous tables.",
      "All the previous contest setup will be lost and a new one will be made.": "All the previous contest setup will be lost and a new one will be made.",
      'Counting votes': 'Counting votes',
      "Heating": 'Heating',
      "Heated": "Heated",
      "No heated": "No heated",
      "Comment": "Comment",
      "Table Leader": "Table Leader",
      "Set Table Leader": "Set Table Leader",
      "Table Leader Setup": "Table Leader Setup",
      "Move Judge": "Move Judge",
      "Details": "Details",
      "Are you sure that you want to change this table's leader to ": "Are you sure that you want to change this table's leader to ",
      "Warning": "Warning",
      "Table Leader changed successfully!": "Table Leader changed successfully!",
      "Change Table Leader": "Change Table Leader",
      "Scan QR Code": "Scan QR Code",
      "Scan Another QR Code": "Scan Another QR Code",
      "Loading": "Loading",
      "Product Status": "Product Status",
      "Shipped": "Shipped",
      "Edit": "Edit",
      "Unknown": "Unknown",
      "Receive Cheese": "Receive Cheese",
      "Validate Cheese": "Validate Cheese",
      "Separate Cheese": "Separate Cheese",
      "Product received successfully!": "Product received successfully!",
      "Product validated successfully!": "Product validated successfully!",
      "Product separated successfully!": "Product separated successfully!",
      "Able": "Able",
      "Unable": "Unable",
      "Print Certificate": "Print Certificate",
      "Download Certificate": "Download Certificate",
      "Contest Started": "Contest Started",
      "Table Number": "Table Number",
      "Current Contest Phase": "Current Contest Phase",
      "Contest not started yet.": "Contest not started yet.",
      "Table's Leader Veredic": "Table's Leader Veredic",
      "Grade": "Grade",
      "Print Registration Form": "Print Registration Form",
      "Page Not Found": "Page Not Found",
      "The page you are looking for might have been removed.": "The page you are looking for might have been removed.",
      "Return to the Home Page": "Return to the Home Page",
      "Cheese Award Certificate Release Date": "Cheese Award Certificate Release Date",
      "Print Review Report": "Print Review Report",
      "Reviews by Attributes": "Reviews by Attributes",
      "Export Cheese Receipt Report": "Export Cheese Receipt Report",
      "Add Cheese to the Table": "Add Cheese to the Table",
      "Add Judge to the Table": "Add Judge to the Table",
      "Move Cheese": "Move Cheese",
      "Move cheese to another table": "Move cheese to another table",
      "Add Cheese Judge to the Table": "Add Cheese Judge to the Table",
      "Profession": "Profession",
    },
  },
  es: {
    translation: {
      Search: "Rechercher…",
      "Welcome back": "Bien venido(a)",
      "We've missed you": "Tenga un lindo día.",
    },
  },
  pt: {
    translation: {
      Search: "Zoeken…",
      "Welcome back": "Bem vindo(a)",
      "Yes": "Sim",
      "No": "Não",
      "Dashboard": "Painel de Controle",
      "We've missed you": "Tenha um ótimo dia.",
      "Users": "Usuários",
      "User": "Usuário",
      "user": "usuário",
      "cheese judge": "jurado",
      "cheese maker": "produtor",
      "Edit User": "Editar Usuário",
      "Edit Cheese Maker": "Editar Produtor",
      "Edit Cheese Judge": "Editar Jurado",
      "Add User": "Adicionar Novo Usuário",
      "Add Cheese Maker": "Adicionar Novo Produtor",
      "Add Cheese Judge": "Adicionar Novo Jurado",
      "Name": "Nome",
      "Full Name": "Nome Completo",
      "Lastname": "Sobrenome",
      "E-mail": "E-mail",
      "Language": "Idioma",
      "Role": "Função",
      "Actions": "Ações",
      "New User": "Novo Usuário",
      "Save": "Salvar",
      "Save Changes": "Salvar Alterações",
      "Cheese Maker": "Produtor",
      "Cheese Judge": "Jurado",
      "Cheese Judges": "Jurados",
      "Admin": "Administrador",
      "Password": "Senha",
      "Confirm Password": "Confirmar Senha",
      "The password must have at least 10 characters, with at least one uppercase letter, one lowercase letter and one number.": "A senha deve conter ao menos 10 caracteres, sendo pelo menos um caracter em caixa alta, um caracter em caixa baixa e um número.",
      "Portuguese": "Português",
      "English": "Inglês",
      "Spanish": "Espanhol",
      "Italian": "Italiano",
      "French": "Francês",
      "Close": "Fechar",
      "Occured an Error": "Ocorreu um erro",
      "Done": "Concluir",
      "User registred successfully": "Usuário cadastrado com sucesso",
      "Required field": "O campo é obrigatório",
      "User updated successfully": "Usuário atualizado com sucesso",
      "Cheese subscribed successfully": "Queijo inscrito com sucesso",
      "Cheese updated successfully": "Queijo atualizado com sucesso",
      "Farm's Name": "Nome da Fazenda",
      "Farm's Legal Representative": "Representante Legal da Fazenda",
      "Country": "País",
      "State": "Estado",
      "Status": "Status",
      "Active": "Ativo",
      "Inactive": "Inativo",
      "Cheese Makers": "Produtores",
      "Cheese Judges": "Jurados",
      "User agreed with the terms and Contest Regulation.": "Concordou com os termos e regulamento do concurso.",
      "I have read and agreed to the Terms and Contest Regulation.": "Li e concordo com todos os termos e regulamento do concurso.",
      "Every term and contest regulation have been agreed by the user.": "Todos os termos e regulamento do concurso foram aprovados pelo usuário.",
      "Website": "Site",
      "Phone Number": "Telefone Fixo",
      "Cellphone Number": "Celular",
      "Document Number": "Número do Documento",
      "RG": "RG",
      "CPF": "CPF",
      "CNPJ": "CNPJ",
      "Passport": "Passaporte",
      "ID Document Type": "Tipo de Documento de Identificação",
      "City": "Cidade",
      "Zip-Code": "CEP",
      "This is the name that would be shown in your contest certificate.": "Nome que irá aparecer no certificado de premiação.",
      "Name of the property responsible.": "Nome do responsável da propriedade",
      "Cheese Maker updated successfully": "Produtor alterado com sucesso",
      "Cheese Judge updated successfully": "Jurado alterado com sucesso",
      "Cheese Maker registered successfully": "Produtor cadastrado com sucesso",
      "Cheese Judge registered successfully": "Jurado cadastrado com sucesso",
      "Curator updated successfully": "Curator alterado successfully",
      "Curator registered successfully": "Curator cadastrado successfully",
      "A confirmation e-mail was sent to the registered e-mail address.": "Um e-mail de confirmação foi enviado para o e-mail cadastrado.",
      "This cheese maker needs to be activated by the user.": "Este produtor precisa ser ativado pelo usuário.",
      "This cheese judge needs to be activated by the user.": "Este jurado precisa ser ativado pelo usuário.",
      "Cheese Maker Registration": "Cadastro de Produtor",
      "Cheese Judge Registration": "Cadastro de Jurado",
      "New Cheese Maker": "Novo Produtor",
      "New Cheese Judge": "Novo Jurado",
      "Subscribe another cheese": "Inscrever outro queijo",
      "Subscribe new cheese": "Inscrever novo queijo",
      "Edit Cheese Subscription": "Editar Inscrição de Queijo",
      "Go to payment": "Ir para o pagamento",
      "Cheese Name": "Nome do Queijo",
      "Certificate Type": "Tipo de Certificação",
      "Municipal Certificate": "Municipal",
      "State Certificate": "Estadual",
      "Federal Certificate": "Federal",
      "Selo Arte": "Selo Arte",
      "Art Stamp": "Selo Arte",
      "Other": "Outro",
      "Certificate: Attach the cheese's certificate": "Certificado - Anexe o certificado do queijo.",
      "Cheese's Characteristics": "Características do Queijo",
      "Category": "Categoria",
      "Origin": "Origem",
      "Milk Type": "Tipo de Leite",
      "Raw": "Cru",
      "Pasteurized": "Pasteurizado",
      "Manufacturing Date": "Data de Fabricação",
      "Dough Treatment": "Tratamento da Massa",
      "Rind Type": "Tipo da Casca do Queijo",
      "Cheese Subscriptions": "Inscrições de Queijo",
      "Cheese Subscription": "Inscrição de Queijo",
      "New Cheese Subscription": "Nova Inscrição de Queijo",
      "Certificate": "Certificado",
      "Code": "Código",
      "Paid": "Pago",
      "Subscribed Cheeses": "Queijos Inscritos",
      "Cheeses": "Queijos",
      "Subscriptions": "Inscrições",
      "Orders": "Pedidos",
      "Orders History": "Histórico de Pedidos",
      "Reviews": "Avaliações",
      "Administrative": "Administrativo",
      "Brazil": "Brasil",
      "Italy": "Itália",
      "United States": "Estados Unidos",
      "France": "França",
      "Germany": "Alemanha",
      "Spain": "Espanha",
      "Approved": "Aprovado",
      "Disapproved": "Reprovado",
      "Pending": "Pendente",
      "Approval": "Aprovação",
      "Occupation": "Profissão",
      "Rows per page:": "Itens por página:",
      "of": "de",
      "more than": "mais que",
      "Proficiency Languages": "Idiomas de Domínio",
      "List every language you have proficiency.": "Liste todos os idiomas que você domina.",
      "Male": "Masculino",
      "Female": "Feminino",
      "Other": "Outro",
      "Nationality": "Nacionalidade",
      "Gender": "Sexo",
      "Educational Background": "Formação",
      "Professional Activity": "Atividade Profissional",
      "Company": "Empresa que Trabalha",
      "Cheese sensory analysis experience": "Experiência de análise sensorial de queijo",
      "I've never been a cheese contest judge": "Nunca fui jurado de concurso de queijo",
      "Are there any restrictions on judging a certain type of cheese?": "Há restrição para julgar algum tipo de queijo?",
      "Describe your dietary restriction": "Descreva a sua restrição alimentar",
      "Cart": "Carrinho",
      "Add File": "Adicionar Arquivo",
      "Delete": "Excluir",
      "Are you sure that you want to delete": "Tem certeza que deseja excluir o",
      "deleted successfully!": "excluído com sucesso!",
      "Item deleted successfully!": "Exclusão realizada com sucesso!",
      "Cart": "Carrinho",
      "Description": "Descrição",
      "Amount": "Valor",
      "Go to payment": "Ir para o pagamento",
      "Items": "Itens",
      "Remove": "Remover",
      "Order Summary": "Resumo do Pedido",
      "Contest Parameters": "Parâmetros do Concurso",
      "Parameters updated successfully!": "Parâmetros atualizados com sucesso!",
      "Contest Subscripition End Date": "Data Final de Inscrição no Concurso",
      "Contest Subscripition Start Date": "Data Inicial de Inscrição no Concurso",
      "Payment Expiring Date": "Prazo de Vencimento do Boleto",
      "Cheese Subscription Cost (R$)": "Valor da Inscrição do Queijo (R$)",
      "Contest Subscription Cost (R$)": "Valor da Inscrição no Concurso (R$)",
      "Logout": "Sair",
      "ID da Propriedade": "ID da Propriedade",
      "Passaporte": "Passaporte",
      "Approve": "Aprovar",
      "Disapprove": "Reprovar",
      "Success!": "Sucesso!",
      "Warning!": "Atenção!",
      "Invoices": "Faturamento",
      "You still didn't completed your subscription in the Expoqueijo's 2024 International Cheese Awards!": "Você ainda não concluiu a sua inscrição no Concurso Internacional do Queijo da Expoqueijo 2024!",
      "Finish you subscription clicking in the button below and make the payment of your subscription.": "Faça o pagamento da sua inscrição clicando no botão abaixo.",
      "Order number": "Número do pedido",
      "Order total": "Total do pedido",
      "Thank you for your subscription.": "Obrigada pela inscrição.",
      "Cash Credit Card": "Cartão de Crédito à vista",
      "Credit card in": "Cartão de Crédito em",
      "installments": "parcelas",
      "Pagamento realizado com sucesso!": "Pagamento realizado com sucesso!",
      "Credit Card": "Cartão de Crédito",
      "Payment method": "Escolha a forma de pagamento",
      "YOUR NAME": "SEU NOME",
      "Card Number": "Número do Cartão",
      "Card Name": "Nome do Cartão",
      "Valid Thru": "Validade",
      "Installments": "Parcelas",
      "Finish Order": "Finalizar Pedido",
      "This is a safe environment, payment made in encrypted environment.": "Este é um ambiente seguro, realizado em ambiente criptografato.",
      "Finish Payment": "Concluir Pagamento",
      "Válido até às": "Válido até às",
      "Brasilia time.": "horário de Brasília.",
      "Copy Pix Code": "Copiar Código Pix",
      "Payment Info": "Informações de pagamento",
      "Pix à vista": "Pix à vista",
      "Boleto à vista": "Boleto à vista",
      "Order": "Pedido",
      "Payment Status": "Status do Pagamento",
      "Payment Date": "Data de Pagamento",
      "Value": "Valor",
      "Pago": "Pago",
      "Vencida": "Vencida",
      "Aguardando pagamento": "Aguardando pagamento",
      "Expiring Date": "Data de Vencimento",
      "Expired in": "Vencida em",
      "Print": "Imprimir",
      "Payment No.": "Nº do Pagamento",
      "Hello,": "Olá,",
      "Este é o recibo do pagamento de": "Este é o recibo do pagamento de",
      "feito para Expoqueijo Brasil.": "feito para a Expoqueijo Brasil.",
      "to Expoqueijo Brasil.": "para Expoqueijo Brasil",
      "Este é o recibo vencido de um pedido de": "Este é o recibo vencido de um pedido de",
      "Este é o recibo de um pagamento pendente no valor de": "Este é o recibo de um pagamento pendente no valor de",
      "Order Completed": "Pedido Concluído",
      "Inscricao": "Inscrição do Concurso",
      "Produto": "Inscrição de Queijo",
      "Log In": "Acessar",
      "Forgot your password?": "Esqueci minha senha",
      "Sign up": "Novo usuário? Cadastre-se",
      "Remember me": "Lembrar-me",
      "Back": "Voltar",
      "Change my password": "Solicitar nova senha",
      "We just sent you the e-mail with the instructions to change your password.": "Enviamos as instruções de alteração de senha para o endereço de email associado à sua conta.",
      "Type your e-mail address to receive the instructions to setup your new password.": "Digite o seu e-mail para receber as instruções de definição de nova senha.",
      "Back to login": "Voltar para o Login",
      "Didn't received the email?": "Não recebeu o e-mail?",
      "Check your trash or spam inboxes": "Verifique a sua caixa de spam e/ou lixeira",
      "Check you typed correctly your registred e-mail address": "O endereço de e-mail informado pode ter sido digitado com erros",
      "Make sure that the e-mail address is registred in our database": "Certifique-se que o endereço de e-mail informado possui cadastro no nosso sistema",
      "If you still have truble to access your account, contact our support.": "Se você ainda estiver com problemas para acessar a sua conta, contate o nosso suporte.",
      "Choose your role": "Escolha o tipo de cadastro que deseja",
      "I'm a...": "Sou um...",
      "Paying Method": "Forma de Pagamento",
      "CartaoCredito": "Cartão de Crédito",
      "Order Details": "Detalhe do Pedido",
      "Click on the link in this e-mail to validate your registration.": "Clique no link presente no e-mail para validar seu cadastro.",
      "Return to the login page": "Voltar para o login",
      "Experience details:": "Cite a sua experiência:",
      "Register": "Cadastrar",
      "Successful Registration": "Cadastro realizado com sucesso",
      "E-mail inválido": "E-mail inválido",
      "Campo obrigatório": "Campo obrigatório",
      "Type your new password below.": "Digite sua nova senha abaixo.",
      "New Password": "Nova Senha",
      "Password changed successfully": "Senha alterada com sucesso!",
      "Change password": "Alterar senha",
      "You must attach the certificate.": "Você deve anexar o certificado do queijo.",
      "Cannot read properties of undefined (reading 'url')": "Você deve anexar o certificado do queijo.",
      "Do you really want to delete the file": "Deseja realmente apagar o arquivo",
      "Delete file": "Remover arquivo",
      "The certificate is required": "O certificado é obrigatório",
      "days": "dias",
      "Product Information": "Características do Produto",
      "Cheese Review Sheet": "Ficha de Avaliação do Queijo",
      "Maturation Days": "Dias de Maturação",
      "Product's Code": "Código do Produto",
      "Estragado": "Estragado",
      "Péssimo": "Péssimo",
      "Ruim": "Ruim",
      "Insuficiente": "Insuficiente",
      "Razoável": "Razoável",
      "Suficiente": "Suficiente",
      "Bom": "Bom",
      "Ótimo": "Ótimo",
      "Excelente": "Excelente",
      "Perfeito": "Perfeito",
      "Send Review": "Enviar Avaliação",
      "General Aspect": "Aspecto Global",
      "Color": "Cor",
      "Texture": "Textura",
      "Odor": "Odor",
      "Scent": "Aroma",
      "Consistency": "Consistência",
      "Flavor": "Sabor",
      "Payment": "Pagamento",
      "Title / Aspect": "Título / Aspecto",
      "Title": "Título",
      "Aspect": "Aspecto",
      "Max Rating": "Nota Máxima",
      "Weight": "Peso",
      "Add New Evaluation Field": "Adicionar Novo Campo de Avaliação",
      "Click here to read the Terms and Contest Regulation.": "Clique aqui para ler os termos e regulamento do concurso.",
      "Cheeses": "Queijos",
      "Cheese": "Queijo",
      "Subscription": "Inscrição",
      "Cancel": "Cancelar",
      "Add": "Adicionar",
      "The user read and agreed with the contest terms and regulation.": "O usuário leu e concordou com os termos e regulamento do concurso.",
      "Check your e-mail inbox, and if you don't find the e-mail there, kindly take a look at your spam or junk folder.": "Por favor, verifique a sua caixa de entrada e, caso não encontre o e-mail, dê uma olhada na pasta de spam ou lixo eletrônico.",
      "User not found.": "Usuário não encontrado",
      "Usuário ou senha inválidos!": "Usuário ou senha inválidos!",
      "Queijo Maturado": "Queijo Maturado",
      "Queijo de Untar, Espalhável (Ex. de passar no pão)": "Queijo de Untar, Espalhável (Ex. de passar no pão)",
      "Ricotta": "Ricota",
      "Queijo Coalho": "Queijo Coalho",
      "Queijo de Manteiga": "Queijo de Manteiga",
      "Queijo Pastoso": "Queijo Pastoso",
      "Queijo de Massa Fundida Requeijão em Barra": "Queijo de Massa Fundida Requeijão em Barra",
      "Queijo de Massa Fundida Requeijão Moreno": "Queijo de Massa Fundida Requeijão Moreno",
      "Requeijão em Barra": "Requeijão em Barra",
      "Requeijão Moreno": "Requeijão Moreno",
      "Queijo com Mofo Azul": "Queijo com Mofo Azul",
      "Queijo Aromatizado e/ou Condimentado": "Queijo Aromatizado e/ou Condimentado",
      "Fresco com tratamento (mofo ou condimento)": "Fresco com tratamento (mofo ou condimento)",
      "Fresco sem tratamento": "Fresco sem tratamento",
      "Casca Lisa e/ou Lavada (cascas sem presença de fungos, resinas e demais itens)": "Casca Lisa e/ou Lavada (cascas sem presença de fungos, resinas e demais itens)",
      "Casca Florida (casca com predomínio de fungos filamentosos)": "Casca Florida (casca com predomínio de fungos filamentosos)",
      "Casca Tratada (exemplo: carvão vegetal; resinas; corantes, entre outros.)": "Casca Tratada (exemplo: carvão vegetal; resinas; corantes, entre outros.)",
      "Com Aquecimento": "Com Aquecimento",
      "Sem Aquecimento": "Sem Aquecimento",
      "Queijo Massa Filada": "Queijo Massa Filada",
      "Queijo Massa Não Filada": "Queijo Massa Não Filada",
      "Cru": "Cru",
      "Pausterizado": "Pausterizado",
      "Vaca": "Vaca",
      "Cabra": "Cabra",
      "Ovelha": "Ovelha",
      "Búfala": "Búfala",
      "Mistura de Diferentes Espécies": "Mistura de Diferentes Espécies",
      "Outro": "Outro",
      "Attention!": "Atenção!",
      "Event": "Evento",
      "Awards Info": "Informações do Concurso",
      "Email não confirmado!": "Email não confirmado!",
      "Resend account validation e-mail": "Reenviar email de confirmação",
      "There are no items in your cart.": "Não há itens no seu carrinho.",
      "Contest Phase": "Fase do Concurso",
      "Contest Phases": "Fases do Concurso",
      "Max Grade": "Nota Máxima",
      "Add question": "Adicionar nova questão",
      "Contest Start Date": "Dia de Início do Evento",
      "Contest": "Concurso",
      "Manufacturing Date cannot be after the event initial date.": "Data de Fabricação não pode ser após a data de início do evento.",
      "Payment Status": "Pagamento",
      "Processando pagamento": "Processando pagamento",
      "Não pago": "Não pago",
      "O pagamento não pôde ser concluído: O CPF ou CNPJ informado é inválido.": "O pagamento não pôde ser concluído: O CPF ou CNPJ informado é inválido.",
      "cheese": "queijo",
      "Add phase": "Adicionar fase",
      "Contest Phase Name": "Nome da Fase do Concurso",
      "Contest Phase Order": "Ordenação da Fase do Concurso",
      "Contest Tables": "Mesas do Concurso",
      "Shuffle Tables": "Sortear as mesas",
      "Contest Table ID Code": "Código de Identificação na Mesa",
      "Table Details": "Detalhes da Mesa",
      "Subscriptions by Makers": "Inscrições por Produtor",
      "Your Reviews": "Suas Avaliações",
      "New Curator": "Novo Curador",
      "Curator": "Curador",
      "Curators": "Curadores",
      "Edit Curator": "Editar Curador",
      "Makers With No Subscriptions": "Produtores Sem Inscrições",
      "Phone": "Telefone",
      "Pending Payment": "Pagamento Pendente",
      "Reports": "Relatórios",
      "Table": "Mesa",
      "Do you want to move": "Você deseja mover",
      "to which table?": "para qual mesa?",
      "Move judge to another table": "Mover jurado para outra mesa",
      "Free": "Isento",
      "Move": "Mover",
      "Cheese Judge moved successfully": "Jurado movido com sucesso!",
      "Cheese moved successfully": "Queijo movido com sucesso!",
      "Cheese reviewed successfully": "Queijo avaliado com sucesso!",
      "Jurado sem acesso ao queijo!": "Você não possui permissão para avaliar este queijo.",
      "Queijo já avaliado!": "Queijo já avaliado.",
      "Return": "Voltar",
      "Return to the previous page": "Voltar para página anterior",
      "Search": "Buscar",
      "Filter": "Filtro",
      "Table QR Codes": "QR Codes das Mesas",
      "Print Judges List": "Imprimir Lista de Jurados",
      "Print Cheese's List": "Imprimir Lista de Queijos",
      "Print QR Codes": "Imprimir QR Codes",
      "Untie Priority": "Prioridade no Desempate",
      "Real Time": "Tempo Real",
      "Event in Real Time": "Evento em Tempo Real",
      "Start Phase": "Iniciar Fase",
      "End Phase": "Finalizar Fase",
      "Average Score": "Nota Média",
      "Reviews": "Avaliações",
      "Qtd. Reviewed": "Qtd. Avaliada",
      "Total Reviews": "Total de Avaliações",
      "Fase iniciada com sucesso!": "Fase iniciada com sucesso!",
      "Fase finalizada com sucesso!": "Fase finalizada com sucesso!",
      "Phase already finished.": "Fase já concluída.",
      "Reset Phase": "Resetar Fase",
      "Phase": "Fase",
      "Your Score (%)": "Sua Pontuação (%)",
      'Average Score (%)': 'Nota Média (%)',
      'Winner (%)': 'Vencedor (%)',
      "Category Average Score (%)": "Média da Categoria (%)",
      "Overall Average Score (%)": "Média Geral (%)",
      "Overall Awards Results": "Classificação Geral",
      "Overall Result": "Classificação Geral",
      "Category Result": "Classificação na Categoria",
      "Score": "Pontuação",
      "Awarded": "Premiado",
      "Qualified to Next Phase": "Classificado para Próxima Fase",
      "Awards Results by Category": "Classificação por Categoria",
      "Export": "Exportar para Excel",
      "Classificados da 1ª fase": "Classificados para Segunda Fase",
      "Classificados da 2ª fase": "Classificados para Terceira Fase",
      "Qualified to Second Phase": "Classificados para Segunda Fase",
      "Qualified to Third Phase": "Classificados para Terceira Fase",
      "Qualified by Phase": "Classificados por Fase",
      "General": "Geral",
      "Second Phase": "Segunda Fase",
      "Approve to Second Phase": "Aprovar para próxima fase",
      "Cheese Judge approved to this contest phase!": "Jurado aprovado para esta fase!",
      "Cheeses Qualified by Phase": "Queijos Classificados por Fase",
      "Tente novamente mais tarde.": "Tente novamente mais tarde.",
      "Refresh": "Atualizar",
      "Number of Cheeses by Table": "Quantidade de Queijos por Mesa",
      "Number of Cheese Judges by Table": "Quantidade de Jurados por Mesa",
      "Selected": "Selecionado",
      "Not selected": "Não selecionado",
      "Deselect": "Desselecionar",
      "Jurado aprovado na fase": "Jurado aprovado na fase.",
      "Jurado reprovado na fase": "Jurado reprovado na fase.",
      "Quantitative Assessment Form": "Ficha de Avaliação Quantitativa",
      "Ratings from 0 to ": "Avaliações de 0 à ",
      "Rating from 0 to ": "Avaliação de 0 à ",
      "Selecionados": "Selecionados",
      "Select Judge": "Selecionar Jurado",
      "Add Cheese Judges - Phase": "Adicionar Jurados - Fase",
      "Export Grades": "Exportar com notas do concurso",
      "Reset Event": "Resetar Evento",
      "Reopen Phase": "Reabrir Fase",
      "Are you sure that you want to reopen this phase?": "Tem certeza que deseja reabrir essa fase?",
      "When you reopen a phase, you allow cheese judges to submit new reviews or overwrite their past reviews.": "Quando você reabre uma fase, você permite que jurados enviem novas avaliações ou sobrescrevam avaliações já enviadas.",
      "Select a Category": "Selecione uma categoria",
      'Please wait, exporting data...': 'Aguarde, exportando dados...',
      "Are you sure that you want to re-order the contest tables?": "Tem certeza que deseja sortear as mesas do concurso?",
      "When you re-oder the tables, you change all cheeses and judges from its previous tables.": "Ao sortear as mesas, toda configuração de queijos e jurados posicionados anteriormente nas mesas será alterada.",
      "All the previous contest setup will be lost and a new one will be made.": "Toda configuração do concurso será perdida e uma nova configuração será gerada.",
      'Counting votes': 'Em apuração',
      "Heating": 'Aquecimento',
      "Heated": "Com aquecimento",
      "No heated": "Sem aquecimento",
      "Comment": "Comentário",
      "Table Leader": "Líder da Mesa",
      "Set Table Leader": "Atribuir Líder da Mesa",
      "Table Leader Setup": "Atribuição de Líder da Mesa",
      "Move Judge": "Mover Jurado",
      "Details": "Detalhes",
      "Are you sure that you want to change this table's leader to ": "Tem certeza que deseja alterar o líder da mesa para o ",
      "Warning": "Atenção",
      "Table Leader changed successfully!": "Líder da mesa alterado com sucesso!",
      "Change Table Leader": "Alterar Líder da Mesa",
      "Scan QR Code": "Escanear QR Code",
      "Scan Another QR Code": "Escanear Outro QR Code",
      "Loading": "Carregando",
      "Product Status": "Estado",
      "Shipped": "Recebido",
      "Edit": "Editar",
      "Unknown": "Desconhecido",
      "Receive Cheese": "Receber Queijo",
      "Validate Cheese": "Validar Queijo",
      "Separate Cheese": "Separar Queijo",
      "Product received successfully!": "Produto recebido com sucesso!",
      "Product validated successfully!": "Produto validado com sucesso!",
      "Product separated successfully!": "Produto separado com sucesso!",
      "Able": "Apto",
      "Unable": "Inapto",
      "Print Certificate": "Imprimir Certificado",
      "Download Certificate": "Baixar Certificado",
      "Contest Started": "Concurso Iniciado",
      "Table Number": "Número da Mesa",
      "Current Contest Phase": "Número da Fase",
      "Contest not started yet.": "Concurso ainda não iniciado.",
      "Table's Leader Veredic": "Veredito do Líder da Mesa",
      "Grade": "Nota",
      "Print Registration Form": "Imprimir Ficha de Inscrição",
      "Page Not Found": "Página Não Encontrada",
      "The page you are looking for might have been removed.": "A página que você procura não existe ou foi removida.",
      "Return to the Home Page": "Voltar à página inicial",
      "Cheese Award Certificate Release Date": "Data de Disponibilização do Certificado de Premiação do Queijo",
      "Print Review Report": "Imprimir Relatório de Desempenho",
      "Reviews by Attributes": "Avaliação por Atributo",
      "Export Cheese Receipt Report": "Exportar Relatório de Recebimento de Queijos",
      "Add Cheese to the Table": "Adicionar Queijo à Mesa",
      "Add Judge to the Table": "Adicionar Jurado à Mesa",
      "Move Cheese": "Mover Queijo",
      "Move cheese to another table": "Mover queijo para outra mesa",
      "Add Cheese Judge to the Table": "Adicionar Jurado à Mesa",
      "Profession": "Profissão",
    },
  },
};

let token = "";

if (window.localStorage.getItem("accessToken")) {
  const accessToken = window.localStorage.getItem("accessToken");
  token = jwtDecode(accessToken);
}

// if (token.length != 0)
i18n.use(initReactI18next).init({
  resources,
  lng: token.idioma,
  fallbackLng: "pt",
  interpolation: {
    escapeValue: false,
  },
});

