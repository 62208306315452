import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined';
import HomeIcon from '@mui/icons-material/Home';

const pagesSection = [
  {
    href: "/",
    icon: HomeIcon,
    title: "Home",
    key: 'dashboard',
  },
];

const juradoSection = [
  {
    href: "/avaliacoes",
    icon: RateReviewOutlinedIcon,
    title: "Your Reviews",
    key: 'avaliacoes',
  },
];

let navItems = [
  {
    // title: "Pages",
    pages: pagesSection,
  },
  // {
  //   title: "Cheese Judge",
  //   pages: juradoSection,
  // },
];

export default navItems;