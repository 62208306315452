import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import { darken } from "polished";
// import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  InputBase,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
  Badge,
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import WarningIcon from '@mui/icons-material/Warning';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Menu as MenuIcon } from "@mui/icons-material";
import { listarIncricoesQueijoProdutor } from "../../api/inscricaoQueijo";
import { getToken } from '../../utils/jwt';

// import NavbarNotificationsDropdown from "./NavbarNotificationsDropdown";
// import NavbarMessagesDropdown from "./NavbarMessagesDropdown";
import NavbarLanguagesDropdown from "./NavbarLanguagesDropdown";
import NavbarUserDropdown from "./NavbarUserDropdown";

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  // color: ${(props) => props.theme.header.color};
  color: #919191;
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Search = styled.div`
  border-radius: 2px;
  background-color: ${(props) => props.theme.header.background};
  display: none;
  position: relative;
  width: 100%;

  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
`;

const SearchIconWrapper = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 22px;
    height: 22px;
  }
`;

const Input = styled(InputBase)`
  color: inherit;
  width: 100%;

  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)};
    padding-right: ${(props) => props.theme.spacing(2.5)};
    padding-bottom: ${(props) => props.theme.spacing(2.5)};
    padding-left: ${(props) => props.theme.spacing(12)};
    width: 160px;
  }
`;

const Navbar = ({ onDrawerToggle }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [token, setToken] = useState(null);
  const [open, setOpen] = useState(false);
  const [userRole, setUserRole] = useState('');
  const [estrangeiro, setEstrangeiro] = useState(false);
  const [carrinhoLength, setCarrinhoLength] = useState(0);
  const [invisible, setInvisible] = useState(true);

  useEffect(() => {
    setToken(getToken());
  }, []);

  useEffect(() => {
    if (token) {
      const { role, estrangeiro } = token;
      setEstrangeiro(estrangeiro);
      setUserRole(role);
      initialize();
    }
  }, [token]);

  const initialize = async () => {
    if (userRole === 'Produtor') {
      try {
        const queijos = JSON.parse(localStorage.getItem('queijos'));

        var itensNaoPagos = queijos.filter(queijo => {
          return (queijo.pago === false && (queijo.statusPagamento === 'Aberto' || queijo.statusPagamento === 'Vencida'))
        })

        if (itensNaoPagos.length > 0) {
          setInvisible(false);
          setCarrinhoLength(itensNaoPagos.length);
        }
        else {
          setInvisible(true);
          setCarrinhoLength(0);
        }
      } catch {
        clearInterval(intervalId);
      }
    }
  };

  const checkCarrinho = () => {
    initialize();
  };

  //atualiza o contador do carrinho
  var intervalId = setInterval(checkCarrinho, 2000);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <AppBar position="sticky" elevation={0} className="app-bar">
        <Toolbar>
          <Grid container alignItems="center">
            <Grid item sx={{ display: { xs: "block", md: "none" } }}>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={onDrawerToggle}
                size="large"
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            {/*<Grid item>
               <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <Input placeholder={t("Search")} />
              </Search> 
            </Grid>*/}
            <Grid item xs />
            <Grid item>
              {(userRole === 'Produtor' && estrangeiro === 'false') &&
                <IconButton
                  color="inherit"
                  aria-label="Carrinho"
                  size="large"
                  onClick={() => navigate("/carrinho")}
                >
                  <Badge badgeContent={carrinhoLength} color="warning" invisible={invisible}>
                    <ShoppingCartIcon />
                  </Badge>
                </IconButton>
              }
              {/* <NavbarMessagesDropdown />
              <NavbarNotificationsDropdown /> */}
              <NavbarLanguagesDropdown />
              <NavbarUserDropdown />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="pagamento-dialog-title"
        className='pagamento-dialog'
      >
        <div>
          <DialogContent
            sx={{
              textAlign: 'center',
              margin: 10
            }}
          >
            <WarningIcon
              color='error'
              sx={{
                fontSize: 100,
                marginBottom: 2
              }}
            />
            <DialogContentText sx={{ fontSize: 32, color: '#000' }}>
              {t("Attention!")}
            </DialogContentText>
            <br />
            <DialogContentText sx={{ fontSize: 16, color: '#000' }}>
              {t("You still didn't completed your subscription in the Expoqueijo's 2024 International Cheese Awards!")} <br /><br />
              {t("Finish you subscription clicking in the button below and make the payment of your subscription.")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>
              {t("Done")}
            </Button>
            <Button onClick={() => navigate("/carrinho")}>
              {t("Go to payment")}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </React.Fragment>
  );
};
export default withTheme(Navbar);