import React, { useState, useEffect } from 'react';
import { Formik } from "formik";
import {
    Box,
    Grid,
    Button,
    Select,
    TextField,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    CircularProgress,
    FormControlLabel,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    FormLabel,
    RadioGroup,
    Radio,
    Chip,
    OutlinedInput,
} from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import SenhaInput from "../../forms/Senha/SenhaInput";
import InputMask from "react-input-mask";
import * as Yup from "yup";
import YupPassword from 'yup-password';
import { useNavigate } from "react-router-dom";
import { obterPaises } from "../../../api/countriesnow";
import { inserirJurado } from "../../../api/jurados";
import { inserirUsuario } from "../../../api/usuarios";
import { useTranslation } from "react-i18next";
import { tipoDocBrasilJurado, tipoDocInternacionalJurado } from '../../../api/tipoDocumentos';

YupPassword(Yup);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const idiomasDominioOptions = [
    'Português',
    'Inglês',
    'Espanhol',
    'Italiano',
    'Francês',
    'Outro',
];

const formValues = {
    nome: '',
    email: '',
    celular: '',
    cep: '',
    pais: '',
    estado: '',
    cidade: '',
    tipoDocumento: '',
    numeroDocumento: '',
    nacionalidade: '',
    idiomas: [],
    sexo: '',
    formacao: '',
    atividadeProfissional: '',
    ondeTrabalha: '',
    experiencia: '',
    restricao: false,
    descricaoRestricao: '',
    termoAceito: false,
    senha: '',
};

const validationSchema = Yup.object().shape({
    nome: Yup.string().required("Campo obrigatório"),
    email: Yup.string().email("E-mail inválido").max(255).required("Campo obrigatório"),
    celular: Yup.string().required("Campo obrigatório"),
    cep: Yup.string().length(9, "O CEP deve ter 8 dígitos"),
    pais: Yup.string(),
    estado: Yup.string().required("Campo obrigatório"),
    cidade: Yup.string().required("Campo obrigatório"),
    tipoDocumento: Yup.string(),
    numeroDocumento: Yup.string().required("Campo obrigatório"),
    nacionalidade: Yup.string().required("Campo obrigatório"),
    idiomas: Yup.array().required("Campo obrigatório"),
    sexo: Yup.string().required("Campo obrigatório"),
    formacao: Yup.string().required("Campo obrigatório"),
    atividadeProfissional: Yup.string().required("Campo obrigatório"),
    ondeTrabalha: Yup.string().required("Campo obrigatório"),
    experiencia: Yup.string(),
    outraExperiencia: Yup.string(),
    restricao: Yup.bool().required("Campo obrigatório"),
    descricaoRestricao: Yup.string(),
    termoAceito: Yup.bool().required("Campo obrigatório"),
    senha: Yup.string()
        .required("Campo obrigatório")
        .min(10, 'Senha curta demais - deve conter ao menos 10 caracteres.')
        .minLowercase(1, 'A senha deve conter ao menos uma letra em caixa baixa.')
        .minUppercase(1, 'A senha deve conter ao menos uma letra em caixa alta.')
        .minNumbers(1, 'A senha deve conter ao menos um número.'),
});

function FormularioJurado() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [error, setError] = useState("");
    const [open, setOpen] = useState(false);
    const [initialValues, setInitialValues] = useState(formValues);
    const [sucesso, setSucesso] = useState(false);
    const [checkedTermos, setCheckedTermos] = useState(false);

    const [idiomasDominioValue, setIdiomasDominioValue] = useState([]);
    const [paises, setPaises] = useState([]);
    const [paisValue, setPaisValue] = useState('');
    const [tipoDocumentoValue, setTipoDocumentoValue] = useState('');
    const [restricaoValue, setRestricaoValue] = useState(false);
    const [experienciaValue, setExperienciaValue] = useState('');
    const [outraExperienciaValue, setOutraExperienciaValue] = useState('');

    useEffect(() => {
        const initialize = async () => {
            let paisesData = await obterPaises('pt');
            setPaises(paisesData);
        };
        initialize();
    }, []);

    const handleClose = () => {
        setError('');
        setOpen(false);
    };

    const openAlert = () => {
        setOpen(true);
    };

    const handleSubmit = async (
        values,
        { resetForm, setErrors, setStatus, setSubmitting }
    ) => {
        try {
            var nome = values.nome.split(' ').slice(0, 1).join(' ');
            var sobrenome = values.nome.split(' ').slice(1).join(' ');

            let usuario = {
                "nome": nome,
                "sobrenome": sobrenome,
                "email": values.email,
                "senha": values.senha,
                "senhaAntiga": '',
                "funcao": "Jurado",
                "idioma": "pt",
            }

            const responseUsuario = await inserirUsuario(usuario);
            const { id } = responseUsuario.data;

            values.usuarioId = id;
            values.pais = paisValue;
            values.nomeDocumento = tipoDocumentoValue;
            values.tipoDocumento = tipoDocumentoValue;
            values.idiomas = idiomasDominioValue;
            values.termoAceito = checkedTermos;
            values.restricao = restricaoValue;
            values.aprovado = false;

            if (experienciaValue === 'Outro')
                values.experiencia = outraExperienciaValue;
            else
                values.experiencia = experienciaValue;

            await inserirJurado(values);

            setSucesso(true);
            resetForm();
            setStatus({ sent: true });
            setSubmitting(false);
            openAlert();

        } catch (error) {
            setStatus({ sent: false });
            setError(error.notificacoes[0].message);
            setSubmitting(false);
            openAlert();
        }
    };

    return (
        <Formik
            setOpen={setOpen}
            open={open}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                status,
            }) => (
                <React.Fragment>
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        className='alert-dialog'
                    >
                        {(open && sucesso) && (
                            <div>
                                <DialogContent
                                    sx={{
                                        textAlign: 'center',
                                        margin: 10
                                    }}
                                >
                                    <CheckCircleOutlineIcon
                                        color='success'
                                        sx={{
                                            fontSize: 100,
                                            marginBottom: 2
                                        }}
                                    />
                                    <DialogContentText id="alert-dialog-description" sx={{ fontSize: 20, color: '#000' }}>
                                        <span sx={{ fontSize: 32 }}>
                                            {t("Successful Registration")}<br />
                                            {t("A confirmation e-mail was sent to the registered e-mail address.")} <br />
                                            {t("Check your e-mail inbox, and if you don't find the e-mail there, kindly take a look at your spam or junk folder.")} <br />
                                            {t("Click on the link in this e-mail to validate your registration.")}
                                        </span>
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={() => navigate("/login")}>
                                        {t("Return to the login page")}
                                    </Button>
                                </DialogActions>
                            </div>
                        )}

                        {(open && error) && (
                            <div>
                                <DialogContent
                                    sx={{
                                        textAlign: 'center',
                                        margin: 10
                                    }}
                                >
                                    <CancelOutlinedIcon
                                        color='error'
                                        sx={{
                                            fontSize: 100,
                                            marginBottom: 2
                                        }}
                                    />
                                    <DialogContentText id="alert-dialog-description" sx={{ fontSize: 18, color: '#000' }}>
                                        {t("Occured an Error")}<br />
                                        {t(error)}
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleClose}>
                                        {t("Close")}
                                    </Button>
                                </DialogActions>
                            </div>
                        )}
                    </Dialog>

                    {isSubmitting ? (
                        <Box display="flex" justifyContent="center" my={6}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <form onSubmit={handleSubmit}>
                            <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 4 }}>
                                <Grid item md={12} sm={12} xs={12}>
                                    <TextField
                                        name="nome"
                                        label={t("Full Name")}
                                        value={values.nome}
                                        error={Boolean(touched.nome && errors.nome)}
                                        fullWidth
                                        helperText={touched.nome && errors.nome}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        variant="outlined"
                                        required
                                    />
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                    <TextField
                                        name="email"
                                        label={t("E-mail")}
                                        value={values.email}
                                        error={Boolean(touched.email && errors.email)}
                                        fullWidth
                                        helperText={touched.email && errors.email}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        variant="outlined"
                                        required
                                    />
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                    <TextField
                                        id="pais"
                                        name="pais"
                                        value={paisValue}
                                        label={t("Country")}
                                        onBlur={handleBlur}
                                        onChange={value => setPaisValue(value.target.value)}
                                        error={Boolean(touched.pais && errors.pais)}
                                        helperText={touched.pais && errors.pais}
                                        my={2}
                                        select
                                        fullWidth
                                        required
                                        defaultValue=""
                                    >
                                        {paises.map((item, index) => (
                                            <MenuItem key={index} value={item.name}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                {paisValue === 'Brasil' &&
                                    <Grid item md={6} sm={12} xs={12}>
                                        <InputMask
                                            mask="99999-999"
                                            value={values.cep}
                                            disabled={false}
                                            maskChar=""
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            required
                                        >
                                            {() => (
                                                <TextField
                                                    name="cep"
                                                    label={t("Zip-Code")}
                                                    error={Boolean(touched.cep && errors.cep)}
                                                    fullWidth
                                                    helperText={touched.cep && errors.cep}
                                                    variant="outlined"
                                                />
                                            )}
                                        </InputMask>
                                    </Grid>
                                }
                                <Grid item md={6} sm={12} xs={12}>
                                    <TextField
                                        name="estado"
                                        label={t("State")}
                                        value={values.estado}
                                        error={Boolean(touched.estado && errors.estado)}
                                        fullWidth
                                        helperText={touched.estado && errors.estado}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        variant="outlined"
                                        required
                                    />
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                    <TextField
                                        name="cidade"
                                        label={t("City")}
                                        value={values.cidade}
                                        error={Boolean(touched.cidade && errors.cidade)}
                                        fullWidth
                                        helperText={touched.cidade && errors.cidade}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        variant="outlined"
                                        required
                                    />
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                    <InputMask
                                        mask={paisValue === 'Brasil' ? "(99) 99999-9999" : '(+99) 999-999-9999'}
                                        value={values.celular}
                                        disabled={false}
                                        maskChar=""
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                    >
                                        {() => (
                                            <TextField
                                                name="celular"
                                                label={t("Cellphone Number")}
                                                error={Boolean(touched.celular && errors.celular)}
                                                fullWidth
                                                required
                                                helperText={touched.celular && errors.celular}
                                                variant="outlined"
                                            />
                                        )}
                                    </InputMask>
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                    <TextField
                                        id="tipoDocumento"
                                        name="tipoDocumento"
                                        value={tipoDocumentoValue}
                                        label={t("ID Document Type")}
                                        onBlur={handleBlur}
                                        onChange={value => setTipoDocumentoValue(value.target.value)}
                                        error={Boolean(
                                            touched.tipoDocumento && errors.tipoDocumento
                                        )}
                                        helperText={
                                            touched.tipoDocumento && errors.tipoDocumento
                                        }
                                        my={2}
                                        select
                                        fullWidth
                                        required
                                        defaultValue=""
                                    >
                                        {paisValue === 'Brasil' ?
                                            tipoDocBrasilJurado.map((item, index) => (
                                                <MenuItem key={index} value={item}>
                                                    {item}
                                                </MenuItem>
                                            )) :
                                            tipoDocInternacionalJurado.map((item, index) => (
                                                <MenuItem key={index} value={item}>
                                                    {item}
                                                </MenuItem>
                                            ))}
                                    </TextField>
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                    {paisValue === 'Brasil' ?
                                        <InputMask
                                            mask={tipoDocumentoValue === 'CPF' ?
                                                '999.999.999-99' : "99.999.999-9"}
                                            value={values.numeroDocumento}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            disabled={false}
                                            maskChar=""
                                        >
                                            {() => (
                                                <TextField
                                                    name="numeroDocumento"
                                                    label={t("Document Number")}
                                                    error={Boolean(touched.numeroDocumento && errors.numeroDocumento)}
                                                    fullWidth
                                                    helperText={touched.numeroDocumento && errors.numeroDocumento}
                                                    variant="outlined"
                                                    required
                                                />
                                            )}
                                        </InputMask> :
                                        <TextField
                                            name="numeroDocumento"
                                            value={values.numeroDocumento}
                                            error={Boolean(touched.numeroDocumento && errors.numeroDocumento)}
                                            helperText={touched.numeroDocumento && errors.numeroDocumento}
                                            label={t("Document Number")}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            inputProps={{ maxLength: 9 }}
                                            fullWidth
                                            my={2}
                                            required
                                        />
                                    }
                                </Grid>
                                <Grid item md={12}>
                                    <FormControl sx={{ marginTop: 2, marginBottom: 2 }} fullWidth>
                                        <InputLabel id="idiomas-label">{t("Proficiency Languages")}</InputLabel>
                                        <Select
                                            labelId="idiomas-label"
                                            id="idiomas"
                                            name="idiomas"
                                            multiple
                                            value={idiomasDominioValue}
                                            onChange={
                                                (event) => {
                                                    const { target: { value } } = event;
                                                    setIdiomasDominioValue(
                                                        // On autofill we get a stringified value.
                                                        typeof value === 'string' ? value.split(',') : value,
                                                    );
                                                }}
                                            input={<OutlinedInput id="select-multiple-languages" label="Idiomas de Domínio" />}
                                            renderValue={(selected) => (
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                    {selected.map((value) => (
                                                        <Chip key={value} label={value} />
                                                    ))}
                                                </Box>
                                            )}
                                            MenuProps={MenuProps}
                                        >
                                            {idiomasDominioOptions.map((name) => (
                                                <MenuItem key={name} value={name}>
                                                    {name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText>{t("List every language you have proficiency.")}</FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                    <TextField
                                        name="sexo"
                                        value={values.sexo}
                                        label={t("Gender")}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        error={Boolean(
                                            touched.sexo && errors.sexo
                                        )}
                                        helperText={
                                            touched.sexo && errors.sexo
                                        }
                                        my={2}
                                        select
                                        fullWidth
                                        required
                                    >
                                        <MenuItem value="Masculino">{t("Male")}</MenuItem>
                                        <MenuItem value="Feminino">{t("Female")}</MenuItem>
                                        <MenuItem value="Outro">{t("Other")}</MenuItem>
                                    </TextField>
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                    <TextField
                                        name="nacionalidade"
                                        label={t("Nationality")}
                                        value={values.nacionalidade}
                                        error={Boolean(touched.nacionalidade && errors.nacionalidade)}
                                        fullWidth
                                        helperText={touched.nacionalidade && errors.nacionalidade}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        variant="outlined"
                                        required
                                    />
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                    <TextField
                                        name="formacao"
                                        label={t("Educational Background")}
                                        value={values.formacao}
                                        error={Boolean(touched.formacao && errors.formacao)}
                                        fullWidth
                                        helperText={touched.formacao && errors.formacao}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        variant="outlined"
                                        required
                                    />
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                    <TextField
                                        name="atividadeProfissional"
                                        value={values.atividadeProfissional}
                                        label={t("Professional Activity")}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        error={Boolean(
                                            touched.atividadeProfissional && errors.atividadeProfissional
                                        )}
                                        helperText={
                                            touched.atividadeProfissional && errors.atividadeProfissional
                                        }
                                        my={2}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                    <TextField
                                        name="ondeTrabalha"
                                        value={values.ondeTrabalha}
                                        label={t("Company")}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        error={Boolean(
                                            touched.ondeTrabalha && errors.ondeTrabalha
                                        )}
                                        helperText={
                                            touched.ondeTrabalha && errors.ondeTrabalha
                                        }
                                        my={2}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item md={6} sm={12} xs={12} />
                                <Grid item md={6} sm={12} xs={12}>
                                    <TextField
                                        name="experiencia"
                                        value={experienciaValue}
                                        label={t("Cheese sensory analysis experience")}
                                        onBlur={handleBlur}
                                        onChange={value => setExperienciaValue(value.target.value)}
                                        error={Boolean(touched.experiencia && errors.experiencia)}
                                        helperText={touched.experiencia && errors.experiencia}
                                        my={2}
                                        select
                                        fullWidth
                                        required
                                    >
                                        <MenuItem value="Nunca foi jurado">{t("I've never been a cheese contest judge")}</MenuItem>
                                        <MenuItem value="Expoqueijo 2021">Expoqueijo 2021</MenuItem>
                                        <MenuItem value="Expoqueijo 2022">Expoqueijo 2022</MenuItem>
                                        <MenuItem value="Expoqueijo 2023">Expoqueijo 2023</MenuItem>
                                        <MenuItem value="Outro">{t("Other")}</MenuItem>
                                    </TextField>
                                </Grid>
                                {experienciaValue === 'Outro' &&
                                    <Grid item md={6} sm={12} xs={12}>
                                        <TextField
                                            name="outraExperiencia"
                                            value={outraExperienciaValue}
                                            label={t("Experience details:")}
                                            onBlur={handleBlur}
                                            onChange={value => setOutraExperienciaValue(value.target.value)}
                                            error={Boolean(
                                                touched.outraExperiencia && errors.outraExperiencia
                                            )}
                                            helperText={
                                                touched.outraExperiencia && errors.outraExperiencia
                                            }
                                            my={2}
                                            fullWidth
                                            required
                                        />
                                    </Grid>
                                }
                                <Grid item md={12} sm={12} xs={12}>
                                    <FormControl sx={{ m: 3 }}>
                                        <FormLabel id="restricao-label">{t("Are there any restrictions on judging a certain type of cheese?")}</FormLabel>
                                        <RadioGroup
                                            aria-labelledby="restricao-label"
                                            defaultValue={false}
                                            name="restricao"
                                            value={restricaoValue}
                                            onChange={(event) => {
                                                var isTrue = (event.target.value === 'true');
                                                if (isTrue)
                                                    setRestricaoValue(isTrue)
                                                else
                                                    setRestricaoValue(false);
                                            }}
                                        >
                                            <FormControlLabel value={true} control={<Radio />} label={t("Yes")} />
                                            <FormControlLabel value={false} control={<Radio />} label={t("No")} />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                {restricaoValue &&
                                    <Grid item md={6} sm={12} xs={12}>
                                        <TextField
                                            name="descricaoRestricao"
                                            label={t("Describe your dietary restriction")}
                                            value={values.descricaoRestricao}
                                            error={Boolean(touched.descricaoRestricao && errors.descricaoRestricao)}
                                            fullWidth
                                            helperText={touched.descricaoRestricao && errors.descricaoRestricao}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            variant="outlined"
                                            my={2}
                                            required
                                        />
                                    </Grid>
                                }
                                <Grid item md={12}>
                                    <SenhaInput
                                        id="senha"
                                        name="senha"
                                        label={t("Password")}
                                        value={values.senha}
                                        error={Boolean(touched.senha && errors.senha)}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                    />
                                    <FormHelperText>
                                        {t("The password must have at least 10 characters, with at least one uppercase letter, one lowercase letter and one number.")}
                                    </FormHelperText>
                                </Grid>
                                <Grid item md={12}>
                                    <FormControlLabel
                                        name="termoAceito"
                                        required
                                        control={<Checkbox />}
                                        checked={checkedTermos}
                                        onChange={value => setCheckedTermos(value.target.checked)}
                                        label={t("I have read and agreed to the Terms and Contest Regulation.")}
                                    />
                                    <br /><a href="https://expoqueijobrasil.com.br/concurso/regulamento/" target="_blank" style={{ color: '#000', textDecoration: 'underline' }}>{t("Click here to read the Terms and Contest Regulation.")}</a>
                                </Grid>
                                <Grid
                                    item
                                    container
                                    direction="row"
                                    justifyContent="flex-end"
                                >
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                    >
                                        {t("Register")}
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </React.Fragment>
            )}
        </Formik>
    );
}

export default FormularioJurado;