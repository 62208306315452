import axios from "../utils/axios";
import paisesEN from './countries/en/world.json';
import paisesPT from './countries/pt/world.json';
import paisesES from './countries/es/world.json';

// export function obterPaises() {
//     return axios.get(`https://countriesnow.space/api/v0.1/countries/states`)
//         .catch(function (error) {
//             return error;
//         });
// }

export function obterPaises(idioma) {
    if (idioma == 'es')
        return paisesES
    if (idioma == 'en')
        return paisesEN
    else
        return paisesPT
}

export function obterCidadesUF(pais, uf) {
    var data = { "country": pais, "state": uf }
    return axios.post(`https://countriesnow.space/api/v0.1/countries/state/cities`, data)
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            return error;
        });
}